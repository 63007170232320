import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import { ConversionService } from "@core/services/conversion/conversion.service";

@Component({
  selector: 'app-temperature-input',
  templateUrl: './temperature-input.component.html',
  styleUrls: ['./temperature-input.component.scss']
})
export class TemperatureInputComponent implements OnInit {

  currentTemperatureUnits = 'metric';
  currentTemperature: any;

  @Input()
  temperatureInDegreesCelsius: number = 0;

  @Output()
  valueChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(private conversionService: ConversionService) { }

  ngOnInit() {
    this.currentTemperature = {
      c: this.temperatureInDegreesCelsius,
      f: 0
    };
  }

  calculateMetricTemperature(): void {
    this.currentTemperature.c = this.conversionService.degreesFahrenheitToDegreesCelsius(this.currentTemperature.f);
    this.temperatureInDegreesCelsius = this.currentTemperature.c;
    this.valueChanged.emit(this.temperatureInDegreesCelsius);
  }

  calculateImperialTemperature(): void {
    this.currentTemperature.f = this.conversionService.degreesCelsiusToDegreesFahrenheit(this.currentTemperature.c);
    this.temperatureInDegreesCelsius = this.currentTemperature.c;
    this.valueChanged.emit(this.temperatureInDegreesCelsius);
  }

  changeTemperatureUnits(newUnit: string): void {
    this.currentTemperatureUnits = newUnit;
  }

}
