export class ScoringMatrixCalculation {

  calculateQuestionScore(question: any): number {
    if (question.questionType === undefined || question.questionType.value === 0) {
      return question.answer;
    } else if (question.questionType.value === 1) {
      return question.answer * question.options[0].score;
    }
    return question.answer;
  }
}
