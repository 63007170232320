import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  subTitle: string;

  @Input()
  value: string;

  @Output()
  valueChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() { }

  updateValue(value: string): void {
    this.value = value;
    this.valueChanged.emit(this.value);
  }

}
