import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ContextMenuItemComponent} from "@shared/components/controls/context-menu/context-menu-item/context-menu-item.component";

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent implements OnInit {
  menuItems: ContextMenuItemComponent[] = [];

  open = false;

  @Output()
  menuItemClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  addMenuItem(menuItem: ContextMenuItemComponent) {
    this.menuItems.push(menuItem);
  }

  menuItemClick(action: any): void {
    this.open = false;
    this.menuItemClicked.emit(action);
  }

}
