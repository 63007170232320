import {Component, Input, OnInit} from '@angular/core';
import {ScrollingTabsComponent} from '../scrolling-tabs.component';

@Component({
  selector: 'app-scrolling-tab',
  templateUrl: './scrolling-tab.component.html',
  styleUrls: ['./scrolling-tab.component.scss']
})
export class ScrollingTabComponent implements OnInit {
  active: boolean;

  @Input()
  title: string;

  constructor(public tabs: ScrollingTabsComponent) {
    tabs.addTab(this);
  }

  ngOnInit() {
  }

}
