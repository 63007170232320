import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { User } from "@shared/types/models/user.model";
import * as jwt_decode from 'jwt-decode';
import { map } from "rxjs/operators";
import * as rg4js from 'raygun4js';
import { environment } from "environments/environment";
import { NgxPermissionsService } from "ngx-permissions";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient,
    private permissionsService: NgxPermissionsService) {
  }

  login(email: string, password: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/' + 'login', { email, password })
      .pipe(map(user => {
        if (user && user.token) {
          localStorage.setItem('RhcToken', JSON.stringify(user));
          localStorage.setItem('RhcUser', JSON.stringify(user.user));

          let permissions = this.getPermissionsFromUser(user.user);
          this.permissionsService.loadPermissions(permissions);
        }

        rg4js('setUser', {
          identifier: user.user.username,
          isAnonymous: false,
          email: user.user.email,
          firstName: user.user.firstName,
          fullName: user.user.firstName + ' ' + user.user.lastName,
        });

        return user;
      }));
  }

  logout(): void {
    localStorage.removeItem('RhcToken');
  }

  isAuthenticated(): boolean {
    let authenticated = !!localStorage.getItem('RhcToken');
    let permissions = this.getPermissionsFromUser(JSON.parse(localStorage.getItem('RhcUser')));
    this.permissionsService.loadPermissions(permissions);
    return authenticated && !this.isTokenExpired();
  }

  getToken(): string {
    return JSON.parse(localStorage.getItem('RhcToken')).token;
  }

  getUser(): User {
    return JSON.parse(localStorage.getItem('RhcUser'));
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);

    if (decoded.exp === undefined) return null;

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    if (!token) token = this.getToken();
    if (!token) return true;

    const date = this.getTokenExpirationDate(token);
    if (date === undefined) return false;
    return !(date.valueOf() > new Date().valueOf());
  }

  activate(username: string, activationPhrase: string): Observable<any> {
    return this.http.post<any>('api/login/activate', { username, activationPhrase })
      .pipe(map(user => {
        if (user && user.user) {
          localStorage.setItem('RhcUser', JSON.stringify(user.user));
        }

        return user;
      }));
  }

  private getPermissionsFromUser(user: User): string[] {
    const permissions = [];

    if (user.permissions) {
      user.permissions.forEach(p => {
        permissions.push(p.permissionName);
      });
    }

    return permissions;
  }
}
