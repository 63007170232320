import { Component, OnInit } from '@angular/core';
import {AccordianItemComponent} from '@shared/components';

@Component({
  selector: 'app-accordian',
  templateUrl: './accordian.component.html',
  styleUrls: ['./accordian.component.scss']
})
export class AccordianComponent implements OnInit {
  accordianItems: AccordianItemComponent[] = [];

  constructor() { }

  ngOnInit() {
  }

  addAccordianItem(item: AccordianItemComponent) {
    this.accordianItems.push(item);
  }
}
