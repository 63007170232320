import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../../../environments/environment';

export abstract class ApiService<T> {

  private baseUrl = environment.apiUrl;
  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  public url: string;

  protected constructor(protected httpClient: HttpClient, protected apiUrl: string) {
    this.url = this.baseUrl + '/' + this.apiUrl;
  }

  get$(filter: any): Observable<HttpResponse<T[]>> {
    return this.httpClient.get<T[]>(this.url, { params: filter, observe: 'response' });
  }

  getById$(id: string): Observable<T> {
    return this.httpClient.get<T>(this.url + `/${id}`);
  }

  create$(model: any): Observable<T> {
    return this.httpClient.post<T>(this.url, model, this.httpOptions);
  }

  delete$(id: string): Observable<T> {
    return this.httpClient.delete<T>(this.url + `/${id}`);
  }

  update$(model: any): Observable<T> {
    return this.httpClient.put<T>(this.url, model, this.httpOptions);
  }
}
