import { Component, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Time } from '../../../types/interfaces/time.interface';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent {

  @Input()
  time: Time = {
    hour: 0,
    minute: 0,
    second: 0
  };

  @Input()
  showSeconds = false;

  @Output()
  timeChanged = new EventEmitter<Time>();

  constructor(private changeDetector: ChangeDetectorRef) { }

  update() {
    this.changeDetector.detectChanges();
    const time = this.time;
    time.hour = this.clamp(time.hour, 0, 23);
    time.minute = this.clamp(time.minute, 0, 59);
    time.second = this.clamp(time.second, 0, 59);

    this.timeChanged.emit(time);
  }

  padZero(element: HTMLInputElement, value: number) {
    element.value = `${value}`.padStart(2, '0');
  }

  private clamp(value: number, min: number, max: number): number {
    return Math.max(min, Math.min(value, max));
  }

}
