import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faBell} from "@fortawesome/pro-solid-svg-icons";

@Component({
  selector: 'app-reminder-indicator',
  templateUrl: './reminder-indicator.component.html',
  styleUrls: ['./reminder-indicator.component.scss']
})
export class ReminderIndicatorComponent implements OnInit {
  faBell = faBell;

  @Input()
  active = false;

  @Input()
  tooltipContent: any;

  @Output()
  reminderIndicatorClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  reminderIndicatorClick($event): void {
    this.reminderIndicatorClicked.emit($event);
  }

}
