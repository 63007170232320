import {Component, Input, OnInit} from '@angular/core';
import {ContextMenuComponent} from "@shared/components/controls/context-menu/context-menu.component";

@Component({
  selector: 'app-context-menu-item',
  templateUrl: './context-menu-item.component.html',
  styleUrls: ['./context-menu-item.component.scss']
})
export class ContextMenuItemComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  action: any;

  @Input()
  show = true;

  constructor(private menu: ContextMenuComponent) {
    if (this.show) {
      menu.addMenuItem(this);
    }
  }

  ngOnInit() {
  }

  menuItemClick(): void {
    this.menu.menuItemClick(this.action);
  }

}
