import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import { ConversionService } from "@core/services/conversion/conversion.service";

@Component({
  selector: 'app-height-input',
  templateUrl: './height-input.component.html',
  styleUrls: ['./height-input.component.scss']
})
export class HeightInputComponent implements OnInit {

  currentHeightUnits = 'metric';
  currentHeight: any;

  @Input()
  heightInCentimeters: number = 0;

  @Output()
  valueChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(private conversionService: ConversionService) { }

  ngOnInit() {
    this.currentHeight = {
      ft: 0,
      in: 0,
      cm: this.heightInCentimeters
    };
  }

  calculateMetricHeight(): void {
    this.currentHeight.cm = this.conversionService.feetAndInchesToCentimetres(this.currentHeight.ft, this.currentHeight.in);
    this.heightInCentimeters = this.currentHeight.cm;
    this.valueChanged.emit(this.heightInCentimeters);
  }

  calculateImperialHeight(): void {
    let result = this.conversionService.centimetresTofeetAndInches(this.currentHeight.cm);
    this.currentHeight.ft = result.ft;
    this.currentHeight.in = result.in;
    this.heightInCentimeters = this.currentHeight.cm;
    this.valueChanged.emit(this.heightInCentimeters);
  }

  changeHeightUnits(newUnit: string): void {
    this.currentHeightUnits = newUnit;
  }

}
