import { Deserializable } from "../interfaces/deserializable.interface";

export class Configuration implements Deserializable {

  id: string;
  locationId: string;
  telephoneInterviewQuestions: any;
  scoringMatrixSections: any;
  feedbackSections: any;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
