import {Component, Input, OnInit} from '@angular/core';
import {MultiStageModalComponent} from "@shared/components/modals/multi-stage-modal/multi-stage-modal.component";

@Component({
  selector: 'app-modal-step',
  templateUrl: './modal-step.component.html',
  styleUrls: ['./modal-step.component.scss']
})
export class ModalStepComponent implements OnInit {

  active: boolean;

  @Input()
  title: string;

  @Input()
  valid = true;

  constructor(modal: MultiStageModalComponent) {
    modal.addStep(this);
  }

  ngOnInit() {
  }

}
