import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Location} from '@shared/types/models';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  @Output() onToggleMenu: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  toggleMenu(): void {
    this.onToggleMenu.emit();
  }
}
