import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from "ngx-smart-modal";
import { AccountService, UserService, AuthService } from "@core/services";
import { CreateUser, UpdatePassword, User } from "@shared/types/models";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-new-user-modal',
  templateUrl: './new-user-modal.component.html',
  styleUrls: ['./new-user-modal.component.scss']
})
export class NewUserModalComponent implements OnInit {

  user: User = new User();

  userPassword = '';
  userPasswordConfirm = '';
  passwordStrength = 0;

  roles: any[] = [];

  editMode = false;

  title: string;
  confirmText: string;

  constructor(public ngxSmartModalService: NgxSmartModalService,
    public userService: UserService,
    public authService: AuthService,
    public accountService: AccountService,
    public toastr: ToastrService,
    public ngxSpinnerService: NgxSpinnerService) {
  }

  ngOnInit() {
    this.editMode = false;
    this.bindData();
  }
  
  bindData(): void {
    const modalData = this.ngxSmartModalService.getModalData('modal');

    if (modalData && modalData.userId) {
      this.editMode = true;
      this.title = 'Edit User';
      this.confirmText = 'Update User';
      this.userService.getById$(modalData.userId)
        .subscribe(user => {
          this.user = user;
          this.userPassword = 'password';
          this.userPasswordConfirm = 'password';
        });
    } else {
      this.title = 'New User';
      this.confirmText = 'Add User';
    }

    let account$ = null;

    if (modalData && modalData.accountId) {
      account$ = this.accountService.getById$(modalData.accountId);
    } else {
      account$ = this.accountService.getCurrentUserAccount$();
    }

    account$.subscribe(account => {
      this.roles = account.roles;
    });
  }

  create(): void {
    this.ngxSpinnerService.show();

    if (this.editMode) {
      this.userService.update$(this.user)
        .subscribe(user => {
          this.userService.userUpdatedEvent(user);
          this.ngxSpinnerService.hide();
          this.toastr.success('User updated successfully.', 'Success!');
        });
    } else {

      const user = this.authService.getUser();

      const model = new CreateUser(this.user);
      model.accountId = user.accountId;
      model.locationId = user.locationId;
      model.password = this.userPassword;

      this.userService.create$(model)
        .subscribe(cr => {

          this.userService.userCreatedEvent(cr.id);
          this.ngxSpinnerService.hide();
          this.toastr.success('User created successfully.', 'Success!');
        
        });
    }
  }

  setPasswordStrength(strength): void {
    this.passwordStrength = strength;
  }

  selectRole(role): void {
    this.user.role = role;
    this.user.jobTitle = role.title;
  }

}
