import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ResidentApplicationService } from '@core/services';
import { ResidentApplication } from "@shared/types/models";
import { faStar, faExclamationTriangle, faCircle } from '@fortawesome/free-solid-svg-icons';
import { faHouse } from '@fortawesome/pro-solid-svg-icons';
import { SectionComponent } from "@shared/components/controls/section-container/section/section.component";
import { TabsComponent } from "@shared/components/controls/tabs/tabs.component";
import * as moment from 'moment';

@Component({
  selector: 'app-sidepanel',
  templateUrl: './sidepanel.component.html',
  styleUrls: ['./sidepanel.component.scss']
})
export class SidepanelComponent implements OnInit {

  faStar = faStar;
  faExclamationTriangle = faExclamationTriangle;
  faCircle = faCircle;
  faHouse = faHouse;

  residentApplication: ResidentApplication;

  @Input()
  panelName: string;

  @Input()
  currentSection: SectionComponent;

  @ViewChild(TabsComponent, { static: false }) tabsComponent: TabsComponent;

  constructor(private residentApplicationService: ResidentApplicationService) { }

  ngOnInit() {

    this.residentApplicationService.residentApplicationUpdated$.subscribe(residentApplication => {

      this.residentApplication = residentApplication;

      if (this.residentApplication != null) {

        this.residentApplication = new ResidentApplication().deserialize(residentApplication);

      }

    });

  }

  getYears(enlistmentDate: Date, dischargeDate: Date): number {
    if (enlistmentDate == null || dischargeDate == null)
      return 0;
    var enlistment = moment(enlistmentDate);
    var discharge = moment(dischargeDate);
    var duration = moment.duration(discharge.diff(enlistment));
    return duration.years();
  }

  dateIsValid(date: Date): boolean {
    if (date == null)
      return false;
    let dateMoment = moment(date);
    return dateMoment.year() > 1;
  }

  formatServiceTypes(serviceTypes: any[]): string {
    if (serviceTypes == null)
      return "Service Type not set";
    return serviceTypes.map(function (item) {
      return item['displayName'];
    }).join(", ");
  }

}
