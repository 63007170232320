import { Component, OnInit } from '@angular/core';
import { Filter } from "../filter";
import { FilterGroupComponent } from "../../filter-group.component";
import { EnumerationService } from "@core/services/enumeration/enumeration.service";

@Component({
  selector: 'app-application-status-filter',
  templateUrl: './application-status-filter.component.html',
  styleUrls: ['./application-status-filter.component.scss']
})
export class ApplicationStatusFilterComponent extends Filter implements OnInit {

  applicationStatuses: any[];

  constructor(public filterGroup: FilterGroupComponent,
    private enumerationService: EnumerationService) {
    super(filterGroup);
  }

  ngOnInit() {
    this.enumerationService.getResidentApplicationStatuses$()
      .subscribe(response => {
        this.applicationStatuses = response.body;
      });
  }

  updateOperator(operator: string): void {
    this.filterCondition.operator = operator;
    if (this.checkFilterValidity()) {
      this.setAsValid();
      this.filterGroup.applyFilters();
    } else {
      this.filterCondition.isValid = false;
    }
  }

  updateFilter(applicationStatus: any): void {
    if (applicationStatus !== null) {
      this.filterCondition.value = applicationStatus;
      this.setAsValid();
    } else {
      this.isValid = false;
    }
    this.filterGroup.applyFilters();
  }

  private checkFilterValidity(): boolean {
    return this.filterCondition.operator !== null &&
      this.filterCondition.operator !== '' &&
      this.filterCondition.value !== null &&
      this.filterCondition.value !== '';
  }

  getSummary(): string {
    if (this.filterCondition.value) {
      const operator = this.filterCondition.operator === 'is' ? 'is' : 'is not';
      const value = this.filterCondition.value.displayName;
      return `<div>Application Status ${operator} <strong>${value}</strong></div>`;
    }
    return '';
  }

  getQuery(): any {
    if (this.filterCondition.value) {
      return { applicationStatus: this.filterCondition.value.value, applicationStatusMatch: this.filterCondition.operator === 'is' };
    }
    return null;
  }
}
