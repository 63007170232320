import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AddressService} from '@core/services/address/address.service';
import {debounceTime, distinctUntilChanged, filter, tap} from 'rxjs/operators';
import {fromEvent} from 'rxjs';
import { faLocationCircle } from '@fortawesome/pro-solid-svg-icons';
import {Address} from "../../../types/models/address.model";

@Component({
  selector: 'app-address-lookup',
  templateUrl: './address-lookup.component.html',
  styleUrls: ['./address-lookup.component.scss']
})
export class AddressLookupComponent implements AfterViewInit {
  faLocationCircle = faLocationCircle;

  // @ts-ignore
  @ViewChild('input')
  input: ElementRef;

  @Input()
  address: Address;

  @Output()
  addressSelected: EventEmitter<Address> = new EventEmitter<Address>();

  addresses: any[] = [];

  constructor(private addressService: AddressService) { }

  ngAfterViewInit(): void {
    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(150),
        distinctUntilChanged(),
        tap((text) => {
          this.addressService.lookup$(this.input.nativeElement.value)
            .subscribe(res => {
              this.addresses = res.addresses;
            },
            error => {
              this.addresses = [];
            });
        })
      )
      .subscribe();
  }

  getPostcode(): string {
    if (this.address && this.address.postcode) {
      return this.address.postcode;
    }

    return '';
  }

  itemSelected(address: any): void {
    let selectedAddress = new Address();
    selectedAddress.postcode = this.input.nativeElement.value;
    selectedAddress.addressOne = address.value.line_1;
    selectedAddress.addressTwo = address.value.line_2;
    selectedAddress.addressThree = address.value.line_3;
    selectedAddress.addressFour = address.value.line_4;
    selectedAddress.townCity = address.value.town_or_city;
    selectedAddress.county = address.value.county;
    this.addressSelected.emit(selectedAddress);
  }

  clearAddresses() {
    this.addresses = [];
  }
}
