import { Injectable } from '@angular/core';
import {UserService} from '../user/user.service';
import {NgxPermissionsService} from "ngx-permissions";
import {User} from "@shared/types/models";

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  constructor(private userService: UserService,
              private permissionsService: NgxPermissionsService) {
  }

  getUserProfile(): any {
    return JSON.parse(localStorage.getItem('userProfile'));
  }

  setUserProfile(id: string): void {
    this.userService.getById$(id)
      .subscribe(response => {
        let permissions = this.getPermissionsFromUser(response);
        this.permissionsService.loadPermissions(permissions);
        localStorage.setItem('userProfile', JSON.stringify(response));
      });
  }

  getPermissionsFromUser(user: User): string[] {
    const permissions = [];

    if (user.permissions) {
      user.permissions.forEach(p => {
        permissions.push(p.permissionName);
      });
    }

    return permissions;
  }
}
