import {FilterBarComponent} from "../../filter-bar.component";
import {FilterCondition} from "../../../../../types/interfaces/filterCondition.interface";

export abstract class FilterSection {
  filterConditions: FilterCondition[];

  protected constructor(public filterBar: FilterBarComponent) {
    filterBar.addFilterSection(this);
  }

  getSectionSummary(): string {
    let summary = '';

    this.filterConditions.forEach(condition => {
      if (condition.isValid) {
        summary += condition.summary;
      }
    });

    return summary;
  }

  alreadyInUse(type: string): boolean {
    let result = this.filterConditions.findIndex(c => c.type === type);
    return result !== -1;
  }

  applyFilters(): void {
    this.filterBar.applyFilters();
  }

  clearFilters(): void {
    this.filterConditions = [{ type: '' }] as FilterCondition[];
    this.filterBar.applyFilters();
  }
}
