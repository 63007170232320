import {Directive} from "@angular/core";
import {AbstractControl, NG_ASYNC_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";
import {EmailValidator} from "@shared/validators/email.validator";

@Directive({
  selector: '[emailAvailable][ngModel]',
  providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: EmailValidatorDirective, multi: true}]
})
export class EmailValidatorDirective implements Validator {
  constructor(private emailValidator: EmailValidator) {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.emailValidator.checkEmail(control);
  }
}
