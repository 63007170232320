import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidepanel-area',
  templateUrl: './sidepanel-area.component.html',
  styleUrls: ['./sidepanel-area.component.scss']
})
export class SidepanelAreaComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  titleUppercase: boolean = false;

  @Input()
  includeDivider: boolean = false;

  constructor() { }

  ngOnInit() {
    if (this.titleUppercase)
      this.title = this.title.toUpperCase();
  }

}
