import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollTrackingService {
  scrollPosition = 0;
  previousScrollPosition = 0;
  throttled = false;
  throttleDelay = 250;
  scrollThrottleTimeout = null;

  constructor() { }

  setScrollPosition(position) {
    this.previousScrollPosition = this.scrollPosition;
    this.scrollPosition = position;

    if (!this.throttled) {
      this.throttled = true;

      this.scrollThrottleTimeout = setTimeout(() => {
				this.throttled = false;
			}, this.throttleDelay);
    }
  }

  resetScrollPosition() {
    this.previousScrollPosition = 0;
    this.scrollPosition = 0;
  }

  getScrollPosition() {
    return this.scrollPosition;
  }

  scrollingUp() {
    return this.scrollPosition < this.previousScrollPosition;
  }
}
