import {Component, Input, OnInit} from '@angular/core';
import {TabsComponent} from '../tabs.component';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent implements OnInit {
  active: boolean;

  @Input()
  title: string;

  @Input()
  icon: IconDefinition;

  constructor(tabs: TabsComponent) {
    tabs.addTab(this);
  }

  ngOnInit() {
  }

}
