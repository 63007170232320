import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-measure',
  templateUrl: './measure.component.html',
  styleUrls: ['./measure.component.scss']
})
export class MeasureComponent implements OnInit {

  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;

  @Input()
  name: string;

  @Input()
  image: string;

  @Input()
  maximum: number;

  @Input()
  metric: string;

  @Input()
  steps: number;

  @Input()
  active: boolean = false;

  @Input()
  currentValue: number;

  @Output()
  activeChanged: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  valueUpdated: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onCreate: EventEmitter<any> = new EventEmitter<any>();

  step: number;
  percentage: number;
  backgroundPosition: number;

  constructor() {}

  ngOnInit() {
    this.setup();
    this.onCreate.emit(this);
  }

  setup(): void {
    this.step = this.steps;
    this.percentage = 100;

    if (this.active) {
      this.step = (this.currentValue / (this.maximum / (this.steps - 1))) + 1;
    } else {
      this.currentValue = this.maximum;
    }

    this.update(false);
  }

  update(emitUpdate: boolean): void {
    this.percentage = (100 / (this.steps - 1)) * (this.step - 1);
    this.currentValue = (this.maximum / 100) * this.percentage;
    this.currentValue = Math.round(this.currentValue * 100) / 100;
    this.backgroundPosition = (this.step - 1) * 48;

    if (emitUpdate)
      this.valueUpdated.emit(this);
  }

  setActive(): void {
    this.activeChanged.emit(this);
    this.active = true;
    this.update(true);
  }

  setInactive(): void {
    this.active = false;
  }

  increase(): void {
    if (this.step < this.steps) {
      this.step++;
      this.update(true);
    }
  }

  decrease(): void {
    if (this.step > 1) {
      this.step--;
      this.update(true);
    }
  }
}
