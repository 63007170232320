import { Component, OnInit } from '@angular/core';
import { Filter } from "../filter";
import { FilterGroupComponent } from "../../filter-group.component";

@Component({
  selector: 'app-last-updated-filter',
  templateUrl: './last-updated-filter.component.html',
  styleUrls: ['./last-updated-filter.component.scss']
})
export class LastUpdatedFilterComponent extends Filter implements OnInit {

  constructor(public filterGroup: FilterGroupComponent) {
    super(filterGroup);
  }

  ngOnInit() { }

  updateOperator(operator: string): void {
    this.filterCondition.operator = operator;
    if (this.checkFilterValidity()) {
      this.setAsValid();
      this.filterGroup.applyFilters();
    } else {
      this.filterCondition.isValid = false;
    }
  }

  updateValue(value: string): void {
    this.filterCondition.value = value;
    this.setAsValid();
    this.filterGroup.applyFilters();
  }

  private checkFilterValidity(): boolean {
    return this.filterCondition.operator !== null &&
      this.filterCondition.operator !== '' &&
      this.filterCondition.value !== null &&
      this.filterCondition.value !== '';
  }

  getSummary(): string {
    if (this.filterCondition.value) {
      const operator = this.filterCondition.operator === 'morethan' ? 'more than' : 'less than';
      const value = this.filterCondition.value;
      return `<div>Last Updated ${operator} <strong>${value} Days</strong></div>`;
    }
    return '';
  }

  getQuery(): any {
    if (this.filterCondition.value) {
      return { lastUpdated: this.filterCondition.value, lastUpdatedGreaterThan: this.filterCondition.operator === 'morethan' };
    }
    return null;
  }
}
