import { Component, Input, OnInit } from '@angular/core';
import { ResidentApplication} from '@shared/types/models';

@Component({
  selector: 'app-resident-details',
  templateUrl: './resident-details.component.html',
  styleUrls: ['./resident-details.component.scss']
})
export class ResidentDetailsComponent implements OnInit {

  @Input()
  residentApplication: ResidentApplication;

  @Input()
  route: string;

  constructor() { }

  ngOnInit() {
  }

  getAge(): number {
    let age = 0;

    if (this.residentApplication.dateOfBirth && new Date(this.residentApplication.dateOfBirth).getFullYear() > 0) {
      const timeDiff = Math.abs(Date.now() - new Date(this.residentApplication.dateOfBirth).getTime());
      age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
    }

    return age;
  }

  getFirstName(): string {
    let name = this.residentApplication.firstName;
    if (this.residentApplication.preferredName)
      name = this.residentApplication.preferredName;
    return name;
  }

}
