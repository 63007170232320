import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {ScrollingTabComponent} from './scrolling-tab/scrolling-tab.component';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-scrolling-tabs',
  templateUrl: './scrolling-tabs.component.html',
  styleUrls: ['./scrolling-tabs.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ScrollingTabsComponent implements OnInit {
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;

  tabs: ScrollingTabComponent[] = [];

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    dots: false,
    nav: false,
    autoWidth: true,
    items: 4
  };

  constructor() { }

  ngOnInit() {
  }

  addTab(tab: ScrollingTabComponent) {
    if (this.tabs.length === 0) {
      tab.active = true;
    }
    this.tabs.push(tab);
  }

  selectTab(tab: ScrollingTabComponent) {
    this.tabs.forEach(t => {
      t.active = false;
    });
    tab.active = true;
  }

}
