import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ToggleComponent} from './toggle/toggle.component';

@Component({
  selector: 'app-toggle-group',
  templateUrl: './toggle-group.component.html',
  styleUrls: ['./toggle-group.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ToggleGroupComponent implements OnInit {

  items: ToggleComponent[] = [];

  @Input()
  titleAttribute = 'title';

  @Input()
  listView: boolean = false;

  @Input()
  setSelected: boolean = true;

  @Input()
  enabledMinWidth: boolean = true;

  @Output()
  selectedItemChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  addItem(item: ToggleComponent): void {
    this.items.push(item);
  }

  itemSelected(item: ToggleComponent): void {
    this.items.forEach(x => x.selected = false);
    if (this.setSelected)
      item.selected = true;
    this.selectedItemChanged.emit(item);
  }
}
