import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import { ConversionService } from "@core/services/conversion/conversion.service";

@Component({
  selector: 'app-weight-input',
  templateUrl: './weight-input.component.html',
  styleUrls: ['./weight-input.component.scss']
})
export class WeightInputComponent implements OnInit {

  currentWeightUnits = 'metric';
  currentWeight: any;

  @Input()
  weightInKilograms: number = 0;

  @Output()
  valueChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(private conversionService: ConversionService) { }

  ngOnInit() {
    this.currentWeight = {
      st: 0,
      lbs: 0,
      kgs: this.weightInKilograms
    };
  }

  calculateMetricWeight(): void {
    this.currentWeight.kgs = this.conversionService.stonesAndPoundsToKilograms(this.currentWeight.st, this.currentWeight.lbs);
    this.weightInKilograms = this.currentWeight.kgs;
    this.valueChanged.emit(this.weightInKilograms);
  }

  calculateImperialWeight(): void {
    let result = this.conversionService.kilogramsToStonesAndPounds(this.currentWeight.kgs);
    this.currentWeight.st = result.st;
    this.currentWeight.lbs = result.lbs;
    this.weightInKilograms = this.currentWeight.kgs;
    this.valueChanged.emit(this.weightInKilograms);
  }

  changeWeightUnits(newUnit: string): void {
    this.currentWeightUnits = newUnit;
  }

}
