import { Component, OnInit } from '@angular/core';
import * as moment from "moment";
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import { BankComponent } from "@shared/components/controls/bank/bank.component";

@Component({
  selector: 'app-bank-card',
  templateUrl: './bank-card.component.html',
  styleUrls: ['./bank-card.component.scss']
})
export abstract class BankCardComponent implements OnInit {

  faEllipsisV = faEllipsisV;
  quickMenuActive = false;

  protected constructor(public bank: BankComponent) {
    if (bank != null)
      bank.addItem(this);
  }

  ngOnInit() {
  }

  toggleQuickMenu(e: Event): void {
    this.quickMenuActive = !this.quickMenuActive;
    e.stopPropagation();
  }

  hideQuickMenu(e: Event = null): void {
    this.quickMenuActive = false;
    if (e != null)
      e.stopPropagation();
  }

  getFormattedDate(date: Date): string {
    const momentDate = moment(date);
    const yesterday = moment().subtract(1, 'days').startOf('day');
    let formattedDate = '';
    if (momentDate.isBefore(yesterday)) {
      formattedDate = momentDate.fromNow();
    } else {
      formattedDate = momentDate.calendar(null, {
        sameDay: '[today]',
        lastDay: '[yesterday]'
      });
    }

    return formattedDate;
  }

  abstract quickMenuHandler(menuItem: any): void;

}
