import {Component, Input, OnInit} from '@angular/core';
import {SubSectionComponent} from './sub-section/sub-section.component';
import {SectionContainerComponent} from '../section-container.component';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss']
})
export class SectionComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  active = false;

  @Input()
  number: number;

  subSections: SubSectionComponent[] = [];
  currentSubSection: SubSectionComponent;

  constructor(public sectionContainer: SectionContainerComponent) {
    sectionContainer.addSection(this);
  }

  ngOnInit() {
  }

  setActive(subSection: SubSectionComponent): void {
    this.active = true;
    this.setActiveSubSection(subSection);
  }

  setInactive(): void {
    this.active = false;
  }

  addSubSection(subSection: SubSectionComponent) {
    this.subSections.push(subSection);
  }

  removeSubSection(subSection: SubSectionComponent) {
    const index: number = this.subSections.indexOf(subSection);
    if (index !== -1) {
      this.subSections.splice(index, 1);
    }        
  }

  setActiveSubSection(subSection: SubSectionComponent): void {
    this.subSections.forEach(s => s.setInactive());
    subSection.setActive();
    this.currentSubSection = subSection;
    this.sectionContainer.onSectionChange.emit(this.sectionContainer.currentSection);
  }

  setToFinalSubSection(): void {
    const subSection = this.subSections[this.subSections.length - 1];
    this.setActiveSubSection(subSection);
    this.currentSubSection.setToFinalStep();
  }

  nextSubSection(): void {
    const index = this.subSections.findIndex(step => step === this.currentSubSection);
    if (index < (this.subSections.length - 1)) {
      this.setActiveSubSection(this.subSections[index + 1]);
    } else {
      this.sectionContainer.nextSection();
    }
  }

  previousSubSection(): void {
    const index = this.subSections.findIndex(step => step === this.currentSubSection);
    if (index > 0) {
      this.setActiveSubSection(this.subSections[index - 1]);
      this.currentSubSection.setToFinalStep();
    } else {
      this.sectionContainer.previousSection();
    }
  }
}
