import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {faPlus} from '@fortawesome/pro-light-svg-icons';
import {AccordianComponent} from '../accordian.component';

@Component({
  selector: 'app-accordian-item',
  templateUrl: './accordian-item.component.html',
  styleUrls: ['./accordian-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccordianItemComponent implements OnInit {
  faPlus = faPlus;

  @Input()
  active: boolean;

  @Input()
  title: string;

  @Input()
  subtitle: string;

  constructor(public accordian: AccordianComponent) {
    accordian.addAccordianItem(this);
  }

  ngOnInit() {
    this.active = false;
  }

  toggleActive(): void {
    if (!this.active) {
      this.selectAccordianItem(this);
    } else {
      this.active = false;
    }
  }

  selectAccordianItem(item: AccordianItemComponent) {
    this.accordian.accordianItems.forEach(i => {
      i.active = false;
    });
    item.active = true;
  }
}
