import { Component, OnInit, Input } from '@angular/core';
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-add-button',
  templateUrl: './add-button.component.html',
  styleUrls: ['./add-button.component.scss']
})
export class AddButtonComponent implements OnInit {
  faPlusCircle = faPlusCircle;
  @Input()
  disabled = false;

  constructor() { }

  ngOnInit() {
  }

}
