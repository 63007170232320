import { Component, OnInit } from '@angular/core';
import { Filter } from "../filter";
import { FilterGroupComponent } from "../../filter-group.component";

@Component({
  selector: 'app-user-status-filter',
  templateUrl: './user-status-filter.component.html',
  styleUrls: ['./user-status-filter.component.scss']
})
export class UserStatusFilterComponent extends Filter implements OnInit {
  constructor(public filterGroup: FilterGroupComponent) {
    super(filterGroup);
  }

  ngOnInit() {
    this.filterCondition.value = null;
  }

  updateOperator(operator: string): void {
    this.filterCondition.operator = operator;
    if (this.checkFilterValidity()) {
      this.setAsValid();
      this.filterGroup.applyFilters();
    } else {
      this.filterCondition.isValid = false;
    }
  }

  updateValue(value: string): void {
    this.filterCondition.value = value;
    this.setAsValid();
    this.filterGroup.applyFilters();
  }

  private checkFilterValidity(): boolean {
    return this.filterCondition.operator !== null &&
      this.filterCondition.operator !== '' &&
      this.filterCondition.value !== null &&
      this.filterCondition.value !== '';
  }

  getSummary(): string {
    const operator = this.filterCondition.operator === 'is' ? 'is' : 'is not';
    const value = this.filterCondition.value === 'active' ? 'Active' : 'Inactive';
    return `<div>Status ${operator} <strong>${value}</strong></div>`;
  }

  getQuery(): any {
    if (this.filterCondition.operator === 'is' && this.filterCondition.value === 'active' ||
      this.filterCondition.operator === 'isnot' && this.filterCondition.value === 'inactive') {
      return { active: true };
    } else {
      return { active: false };
    }
  }

}
