import { Component, Input, OnInit } from '@angular/core';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { faBell } from "@fortawesome/pro-regular-svg-icons";
import { NgxSmartModalService } from "ngx-smart-modal";
import { DatePipe } from "@angular/common";
import { ToastrService } from "ngx-toastr";
import { ResidentApplicationService } from "@core/services/resident-application/resident-application.service";

@Component({
  selector: 'app-quick-menu-item',
  templateUrl: './quick-menu-item.component.html',
  styleUrls: ['./quick-menu-item.component.scss']
})
export class QuickMenuItemComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  hasSubmenu: boolean;

  @Input()
  disabled: boolean = false;

  @Input()
  newTab: boolean = false;

  faChevronRight = faChevronRight;
  faBell = faBell;

  tooltip: string;

  constructor(public residentApplicationService: ResidentApplicationService,
              public ngxSmartModalService: NgxSmartModalService,
              public datePipe: DatePipe,
              public toastr: ToastrService) { }

  ngOnInit() { }

}
