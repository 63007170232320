import { Component, Input, OnInit } from '@angular/core';
import { ToggleButtonGroupComponent } from '../toggle-button-group.component';

@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss']
})
export class ToggleButtonComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  subtitle: string;

  @Input()
  icon: any;

  @Input()
  value: any;

  @Input()
  selected: boolean;

  constructor(public toggleButtonGroup: ToggleButtonGroupComponent) {
    toggleButtonGroup.addItem(this);
  }

  ngOnInit() {
  }

  itemSelected(): void {
    this.selected = this.toggleButtonGroup.canDeselect ? !this.selected : true;
    this.toggleButtonGroup.itemSelected(this);
  }
}
