import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FilterCondition} from "../../../../types/interfaces/filterCondition.interface";

@Component({
  selector: 'app-filter-section',
  templateUrl: './filter-section.component.html',
  styleUrls: ['./filter-section.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FilterSectionComponent implements OnInit {

  icon = faPlus;

  @Input()
  filterConditions: FilterCondition[];

  @Input()
  template: TemplateRef<any>;

  @Input()
  sectionTitle: string;

  @Input()
  filterSummary: any;

  @Output()
  conditionsCleared: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
  }

  ngOnInit(): void {
  }

  showAddCondition(index: number): boolean {
    return this.filterConditions[index].type !== ''
      && this.filterConditions[index].type !== 'date'
      && index === this.filterConditions.length - 1;
  }

  addCondition(event: Event): void {
    event.stopPropagation();
    this.filterConditions.push({ type: '' } as FilterCondition);
  }

  showClearAll(): boolean {
    return this.filterConditions[0].type !== '';
  }

  clearAllConditions(event: Event): void {
    event.stopPropagation();
    this.conditionsCleared.emit();
  }

}
