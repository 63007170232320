import { Component, OnInit } from '@angular/core';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-remove-button',
  templateUrl: './remove-button.component.html',
  styleUrls: ['./remove-button.component.scss']
})
export class RemoveButtonComponent implements OnInit {
  faTimesCircle = faTimesCircle;

  constructor() { }

  ngOnInit() {
  }

}
