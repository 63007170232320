import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HeaderService} from '@core/services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() onToggleMenu: EventEmitter<any> = new EventEmitter();
  @Input() menuOpen: boolean;

  constructor(public headerService: HeaderService) { }

  ngOnInit() {
  }

  toggleMenu(): void {
    this.onToggleMenu.emit();
  }

}
