import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {FilterSection} from './filter-section/sections/filter-section';

@Component({
  selector: 'app-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FilterBarComponent implements OnInit {
  filterSections: FilterSection[] = [];

  @Output()
  filtersApplied: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  addFilterSection(filterSection: FilterSection): void {
    this.filterSections.push(filterSection);
  }

  applyFilters(): void {
    let query = {};

    this.filterSections.forEach(section => {
      section.filterConditions.forEach(condition => {
        if (condition.isValid) {
          query = Object.assign(query, condition.query);
        }
      })
    });

    this.filtersApplied.emit(query);
  }
}
