import { Component, OnInit } from '@angular/core';
import {SlideComponent} from './slide/slide.component';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {
  slides: SlideComponent[] = [];

  constructor() { }

  ngOnInit() {
  }

  addSlide(slide: SlideComponent) {
    if (this.slides.length === 0) {
      slide.active = true;
    }
    this.slides.push(slide);
  }

  selectSlide(slide: SlideComponent) {
    this.slides.forEach(s => {
      s.active = false;
    });
    slide.active = true;
  }

}
