import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SectionComponent} from '../section.component';
import {StepComponent} from './step/step.component';
import {ProgressStatus} from '@shared/types/enumerations';

@Component({
  selector: 'app-sub-section',
  templateUrl: './sub-section.component.html',
  styleUrls: ['./sub-section.component.scss']
})
export class SubSectionComponent implements OnInit {
  steps: StepComponent[] = [];

  @Input()
  active = false;

  @Input()
  title: string;

  @Input()
  isPlaceholder = false;

  @Input()
  number: number;

  @Output()
  next: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  stepActivated: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  submitText: string = 'Submit';

  @Input()
  showSubmit: boolean = false;

  @Input()
  submitDisabled = false;

  @Input()
  skipSectionEnabled = false;

  @Output()
  submit: EventEmitter<any> = new EventEmitter<any>();

  currentStep: StepComponent;

  constructor(public section: SectionComponent) {
    section.addSubSection(this);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.section.removeSubSection(this);
  }

  addStep(step: StepComponent): void {
    if (this.steps.length === 0) {
      step.active = true;
    }
    this.steps.push(step);
  }

  setActive(): void {
    this.active = true;
    if (this.steps.length > 0) {
      this.setActiveStep(this.steps[0]);
    }
  }

  setInactive(): void {
    this.active = false;
  }

  setActiveStep(step: StepComponent): void {
    this.steps.forEach(s => s.setInactive());
    step.setActive();
    this.currentStep = step;
    this.stepActivated.emit(this.currentStep);
  }

  setToFinalStep(): void {
    const step = this.steps[this.steps.length - 1];
    this.steps.forEach(s => s.setInactive());
    step.setActive();
    this.currentStep = step;
  }

  nextStep(): void {
    const previousStep = this.currentStep;
    const index = this.steps.findIndex(step => step === this.currentStep);
    if (index < (this.steps.length - 1)) {
      this.setActiveStep(this.steps[index + 1]);
    } else {
      this.section.nextSubSection();
    }
    this.next.emit(previousStep);
    this.section.sectionContainer.scrollToTop();
  }

  previousStep(): void {
    const index = this.steps.findIndex(step => step === this.currentStep);
    if (index > 0) {
      this.setActiveStep(this.steps[index - 1]);
    } else {
      this.section.previousSubSection();
    }
  }

  getStatus(): ProgressStatus {
    const anySeen = this.steps.some(x => x.getStatus() === ProgressStatus.InProgress);
    const complete = this.steps.every(x => x.getStatus() === ProgressStatus.Complete);

    if (complete) {
      return ProgressStatus.Complete;
    }
    if (anySeen) {
      return ProgressStatus.InProgress;
    }

    return ProgressStatus.Unseen;
  }

  submitStep(): void {
    this.submit.emit(this.currentStep);
  }

  skipSection(): void {
    this.section.nextSubSection();
    this.section.sectionContainer.scrollToTop();
  }
}
