import { ProgressStatus } from '../enumerations/progressStatus.enum';
import { Deserializable } from "../interfaces/deserializable.interface";
import { Document } from "@shared/types/models/document.model";

export class ResidentApplication implements Deserializable {

  id: string;
  locationId: string;
  createdBy: string;
  createdDate: Date;
  modifiedBy: string;
  modifiedDate: Date;

  applicationFormSent: Date;
  applicationFormReceived: Date;

  title: any;
  firstName: string;
  lastName: string;
  preferredName: string;
  dateOfBirth: Date;
  placeOfBirth: string;
  nationality: any;
  niNumber: string;
  correspondenceAddress: any;
  telephone: string;
  email: string;
  personalDetailsNotes: string;

  maritalStatus: any;
  significantDate: Date;
  childrenOver18?: number;
  childrenUnder18?: number;
  childrenInCloseProximity?: boolean;
  childrenInContact: string;
  homeAndFamilyNotes: string;

  currentAccommodations: any[];
  currentAccommodationOther: string;
  accommodationType: any;
  accommodationTypeOther: string;
  hasGarden?: boolean;
  currentAccommodationNotes: string;

  armyNumber: string;
  lastNameOnEnlistment: string;
  enlistments: any[];
  operationalTheatres: any[];
  rankOnDischarge: string;
  decorationsAndMedals: any[];
  armsQualificationBadges: any[];
  involvementWithMilitaryCommunity: string;
  illnessOrInjuryCausedByService: string;
  prisonerOfWarDetails: string;
  serviceDetailsNotes: string;

  gpName: string;
  gpTelephone: string;
  gpEmail: string;
  gpAddress: any;
  lastingPowerOfAttorney?: boolean;
  willInPlace?: boolean;
  medicalAndLegalNotes: string;

  personalReferences: any[];
  personalRefereesNotes: string;

  occupations: any[];
  currentlySelfEmployed?: boolean;
  hobbiesAndInterests: any[];
  applicantKnowAnyoneLivingAtRoyalHospital: string;
  hasCivilOrMilitaryCriminalConvictions?: boolean;
  civilOrMilitaryCriminalConvictions: any[];
  otherInformationNotes: string;

  applicationReviewNotes: string;
  applicationReviewedById: string;
  applicationReviewedOn: Date;

  telephoneInterviewDate: Date;
  telephoneInterviewNotes: string;
  interviewQuestions: any[];

  scoringMatrices: any[];

  interviewReviewNotes: string;
  interviewReviewedById: string;
  interviewReviewedOn: Date;

  fourDayVisitFrom: Date;
  fourDayVisitTo: Date;
  fourDayVisitNotes: string;
  fourDayVisitFeedbacks: any[];

  gpAssessmentFeedback: any;
  gpAssessmentNotes: string;

  applicationFeedbackApplicationProcessRating: any;
  applicationFeedbackHappyWithInformation?: boolean;
  applicationFeedbackFirstImpressionsRating: any;
  applicationFeedbackFoodRating: any;
  applicationFeedbackStaffInteractionsRating: any;
  applicationFeedbackInPensionersRating: any;
  applicationFeedbackContinuingApplication?: boolean;
  applicationFeedbackNotes: string;

  visitReviewNotes: string;
  visitReviewedById: string;
  visitReviewedOn: Date;
  offerPlace?: boolean;
  declineReason: any;
  completedBy: string;
  completedDate: Date;

  applicationProgress: Progress;
  percentageComplete: number;
  status: any;
  currentSectionTitle: string;

  documents: Document[];

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

class Progress {
  sections: Section[];
}

class Section {
  title: string;
  subSections: SubSection[];
}

class SubSection {
  title: string;
  steps: Step[];
}

class Step {
  status: ProgressStatus;
}
