import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpAuthInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = JSON.parse(localStorage.getItem('RhcToken'));

    if (token && token.token) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token.token}`
        }
      });
    }

    return next.handle(req);
  }
}
