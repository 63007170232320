import {Address} from './address.model';

export class Location {
  id: string;
  name: string;
  description: string;
  address: Address;
  shortAddress: string;
  rooms: any[];
  availableRooms: any[];
}

