import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Filter} from "./filters/filter";

@Component({
  selector: 'app-filter-group',
  templateUrl: './filter-group.component.html',
  styleUrls: ['./filter-group.component.scss']
})
export class FilterGroupComponent implements OnInit {

  filters: Filter[] = [];

  @Output()
  filtersApplied: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  addFilter(filter: Filter): void {
    this.filters.push(filter);
  }

  applyFilters(): void {
    let filterQuery = {};
    let filterSummary: string = '';
    this.filters.forEach(filter => {
      if (filter.filterCondition.isValid) {
        filterQuery = Object.assign(filterQuery, filter.getQuery());
        filterSummary += filter.getSummary();
      }
    });

    this.filtersApplied.emit({ filterSummary: filterSummary, filterQuery: filterQuery });
  }

}
