import { Address } from "./address.model";
import { AdmissionDetails } from "./admissionDetails.model";
import { Enumeration } from "./enumeration.model";
import { Document } from "./document.model";
import { ListItem } from "./listItem.model";
import { Location } from "./location.model";
import { ResidentApplication } from "./residentApplication.model";
import { User } from "./user.model";
import { CalendarEvent } from "./calendarEvent.model";
import { Account } from "./account.model";
import { Configuration } from "./configuration.model";

export const models: any[] = [
  Address,
  AdmissionDetails,
  Enumeration,
  Document,
  ListItem,
  Location,
  ResidentApplication,
  User,
  CalendarEvent,
  Account,
  Configuration];

export * from './address.model';
export * from './admissionDetails.model';
export * from './enumeration.model';
export * from './document.model';
export * from './listItem.model';
export * from './location.model';
export * from './residentApplication.model';
export * from './user.model';
export * from './calendarEvent.model';
export * from './account.model';
export * from './configuration.model';
