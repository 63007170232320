import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Document} from "@shared/types/models";

@Component({
  selector: 'app-profile-image-preview',
  templateUrl: './profile-image-preview.component.html',
  styleUrls: ['./profile-image-preview.component.scss']
})
export class ProfileImagePreviewComponent implements OnInit {

  @Input()
  image: Document;

  @Output()
  profileImageRemoved: EventEmitter<Document> = new EventEmitter<Document>();

  constructor() { }

  ngOnInit() {
  }

  removeProfileImage(): void {
    this.profileImageRemoved.emit(this.image);
  }

}
