import { Component, Input, OnInit } from '@angular/core';
import { ListItem } from "../../../../types/models/listItem.model";
import { IconDefinition } from "@fortawesome/fontawesome-common-types/index";
import { faCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-item-list-item',
  templateUrl: './item-list-item.component.html',
  styleUrls: ['./item-list-item.component.scss']
})
export class ItemListItemComponent implements OnInit {

  faCircle = faCircle;

  @Input()
  item: any;

  @Input()
  propertyName: string;

  @Input()
  propertyPrefix: string;

  @Input()
  propertyImage: string;

  @Input()
  defaultImage: string;

  @Input()
  defaultIcon: IconDefinition;

  @Input()
  defaultColour: string;

  listItem = new ListItem();

  constructor() { }

  ngOnInit() {

    if (this.item !== undefined) {

      if (this.item[this.propertyName] !== undefined) {
        if (typeof this.item[this.propertyName] === "function")
          this.listItem.name = this.item[this.propertyName]();
        else
          this.listItem.name = this.item[this.propertyName];
      }

      if (this.item[this.propertyPrefix] !== undefined) {
        if (typeof this.item[this.propertyName] === "function")
          this.listItem.prefix = this.item[this.propertyPrefix]();
        else
          this.listItem.prefix = this.item[this.propertyPrefix];
      }

      if (this.item[this.propertyImage] !== undefined) {
        this.listItem.image = this.item[this.propertyImage];
      }

      if (this.defaultImage !== undefined && this.listItem.image == undefined) {
        this.listItem.image = this.defaultImage;
      }

      if (this.defaultIcon !== undefined && this.listItem.icon == undefined) {
        this.listItem.icon = this.defaultIcon;
      }

      if (this.defaultColour !== undefined && this.listItem.colour == undefined) {
        this.listItem.colour = this.defaultColour;
      }
    }
  }
}
