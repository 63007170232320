import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-string-list',
  templateUrl: './string-list.component.html',
  styleUrls: ['./string-list.component.scss']
})
export class StringListComponent implements OnInit {
  @Input()
  items: any[];

  @Input()
  placeholder: string = "Add an item";

  @Output()
  itemCreated: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  itemRemoved: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  itemsUpdated: EventEmitter<any[]> = new EventEmitter<any[]>();

  constructor() { }

  ngOnInit() {
  }

  removeItem(item: any): void {
    const index = this.findItemIndex(item);
    this.items.splice(index, 1);
    this.itemRemoved.emit(item);
    this.itemsUpdated.emit(this.items);
  }

  createItem(item: any): void {
    this.items.push(item);
    this.itemCreated.emit(item);
    this.itemsUpdated.emit(this.items);
  }

  private findItemIndex(item: any): any {
    return this.items.findIndex(i => i === item);
  }
}
