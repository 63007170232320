import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NavLinkComponent} from '../nav-link/nav-link.component';
import {faPlus} from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-nav-link-group',
  templateUrl: './nav-link-group.component.html',
  styleUrls: ['./nav-link-group.component.scss']
})
export class NavLinkGroupComponent implements OnInit {
  faPlus = faPlus;

  @Input()
  toggled = false;

  @Input()
  title: string;

  @Input()
  titleClass: string;

  @Output()
  groupOpened: EventEmitter<string> = new EventEmitter<string>();

  items: NavLinkComponent[] = [];

  constructor() { }

  ngOnInit() {
  }

  addItem(item: NavLinkComponent): void {
    this.items.push(item);
  }

  toggleNavigationItems(): void {
    this.toggled = !this.toggled;
    if (this.toggled) {
      this.groupOpened.emit(this.title);
    }
  }
}
