import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faSearch, faSlidersH, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {

  faSearch = faSearch;
  faSlidersH = faSlidersH;
  faCalendar = faCalendar;

  @Output()
  onToggleFilter: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  onToggleCalendar: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  onSearch: EventEmitter<any> = new EventEmitter();

  @Input()
  filterOpen = false;

  @Input()
  filterEnabled = false;

  @Input()
  calendarOpen = false;

  @Input()
  calendarEnabled = false;

  searchTerms = new Subject<string>();

  constructor() { }

  ngOnInit() {
    this.searchTerms.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term: string) => of(term))
    ).subscribe(t => {
      this.onSearch.emit(t);
    });
  }

  search(term: string): void {
    this.searchTerms.next(term);
  }

  toggleFilter(): void {
    this.filterOpen = !this.filterOpen;
    this.calendarOpen = false;
    this.onToggleFilter.emit(this.filterOpen);
  }

  toggleCalendar(): void {
    this.calendarOpen = !this.calendarOpen;
    this.filterOpen = false;
    this.onToggleCalendar.emit(this.calendarOpen);
  }
}
