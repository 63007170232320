import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as moment from 'moment';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;

  @Input()
  selected: any;

  @Output()
  dateSelected: EventEmitter<any> = new EventEmitter<any>();

  month: any;
  weeks: any[];
  day: any;

  constructor() { }

  ngOnInit() {
    this.day = moment();
    const today = this.removeTime(moment());
    this.month = today.clone();

    const start = today.clone();
    start.date(1);
    this.removeTime(start.day(0));
    this.buildMonth(start, this.month);

    this.loadSelectedDate(this.selected);
  }

  loadSelectedDate(date: any): void {
    if (date) {

      const today = this.removeTime(moment(date));
      this.month = today.clone();

      const start = today.clone();
      start.date(1);
      this.removeTime(start.day(0));
      this.buildMonth(start, this.month);
    }
  }

  removeTime(date): any {
    return date.day(0).hour(0).minute(0).second(0).millisecond(0);
  }

  buildMonth(start, month): void {
    this.weeks = [];
    let done = false;
    const date = start.clone();
    let monthIndex = date.month();
    let count = 0;

    while (!done) {
      this.weeks.push({
        days: this.buildWeek(date.clone(), month)
      });

      date.add(1, 'w');
      done = count++ > 2 && monthIndex !== date.month();
      monthIndex = date.month();
    }
  }

  buildWeek(date, month): any {
    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push({
        name: date.format('dd').substring(0, 1),
        number: date.date(),
        isCurrentMonth: date.month() === month.month(),
        isToday: date.isSame(new Date(), 'day'),
        date,
        events: []
      });

      date = date.clone();
      date.add(1, 'd');
    }

    return days;
  }

  next(event: Event): void {
    event.stopPropagation();
    const next = this.month.clone();
    this.removeTime(next.month(next.month() + 1).date(1));
    this.month.month(this.month.month() + 1);
    this.buildMonth(next, this.month);
  }

  previous(event: Event): void {
    event.stopPropagation();
    const previous = this.month.clone();
    this.removeTime(previous.month(previous.month() - 1).date(1));
    this.month.month(this.month.month() - 1);
    this.buildMonth(previous, this.month);
  }

  select(event: Event, day: any): void {
    event.stopPropagation();
    this.selected = day.date;
    this.dateSelected.emit(day.date);
  }
}
