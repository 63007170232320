import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import * as moment from 'moment';
import { CalendarComponent } from "../calendar/calendar.component";

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit, OnChanges {

  faCalendar = faCalendar;

  @Input()
  selectedDate: any;

  @Input()
  useDefaultDate: boolean = true;

  @Output()
  dateChanged: EventEmitter<any> = new EventEmitter<any>();

  calendarOpen = false;

  @ViewChild(CalendarComponent, { static: false }) calendar: CalendarComponent;

  constructor() { }

  ngOnInit() {

    if (this.selectedDate != null) {
      this.selectedDate = moment(this.selectedDate);
    }

    if (this.useDefaultDate && this.selectedDate != null && this.selectedDate.year() <= 0) {
      this.selectedDate = moment();
    }

    if (!this.useDefaultDate && this.selectedDate != null && this.selectedDate.year() <= 0) {
      this.selectedDate = null;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedDate && !moment.isMoment(changes.selectedDate.currentValue)) {
      this.selectedDate = moment(changes.selectedDate.currentValue);
    }
  }

  toggleCalendar(event = null): void {
    if (event != null)
      event.stopPropagation();

    this.calendarOpen = !this.calendarOpen;
  }

  dateSelected(date: any): void {
    this.selectedDate = date;
    this.dateChanged.emit(date);
    this.toggleCalendar();
  }

  selectedDateChange(event): void {
    var date = moment(event, 'DD/MM/YYYY', true);
    if (date.isValid()) {
      this.selectedDate = date;
      this.dateChanged.emit(date);
      this.calendar.loadSelectedDate(date);
    }
  }

}
