import { Injectable } from '@angular/core';
import { environment } from "../../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import EnumerationContentMap from './enumerationContentMap';

@Injectable({
  providedIn: 'root'
})
export class EnumerationService {

  private baseUrl = environment.apiUrl;
  public url: string;

  protected constructor(protected httpClient: HttpClient) {
    this.url = this.baseUrl + '/enumerations';
  }

  private getRequest(route: string): Observable<any> {
    return this.httpClient.get(`${this.url}/${route}`, { observe: 'response' });
  }

  getTitles$(): Observable<any> {
    return this.getRequest('titles');
  }

  getDeclineReasons$(): Observable<any> {
    return this.getRequest('declineReasons');
  }

  getMaritalStatuses$(): Observable<any> {
    return this.getRequest('maritalStatuses');
  }

  getDecorationTypes$(): Observable<any> {
    return this.getRequest('decorationTypes');
  }

  getResidentApplicationStatuses$(): Observable<any> {
    return this.getRequest('residentApplicationStatuses');
  }

  getUnitsOfTime$(): Observable<any> {
    return this.getRequest('unitsOfTime');
  }

  getResidentApplicationEnumerations$(): Observable<any> {
    return this.getRequest('residentApplicationEnumerations');
  }

  getScoringMatrixQuestionTypes(): Observable<any> {
    return this.getRequest('scoringMatrixQuestionTypes');
  }

  getTypeName(item: any): string {
    let name = item.$type.split(',')[0].split('.').splice(-1)[0] + item.value;
    return name;
  }

  getIcon(item: any): string {
    if (item) {
      return EnumerationContentMap[this.getTypeName(item)].icon;
    }
    return '';
  }
}
