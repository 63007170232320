import { Component, OnInit } from '@angular/core';
import {ModalBase} from "@shared/types/bases/modal-base";
import {NgxSmartModalService} from "ngx-smart-modal";
import {ModalStepComponent} from "@shared/components/modals/multi-stage-modal/modal-step/modal-step.component";
import {faCheckCircle, faPenAlt } from "@fortawesome/pro-solid-svg-icons";

@Component({
  selector: 'app-multi-stage-modal',
  templateUrl: './multi-stage-modal.component.html',
  styleUrls: ['./multi-stage-modal.component.scss']
})
export class MultiStageModalComponent extends ModalBase implements OnInit {

  faCheckCircle = faCheckCircle;
  faPenAlt = faPenAlt;

  steps: ModalStepComponent[] = [];

  constructor(public ngxSmartModalService: NgxSmartModalService) {
    super(ngxSmartModalService);
  }

  ngOnInit() {
  }

  addStep(step: ModalStepComponent) {
    if (this.steps.length === 0) {
      step.active = true;
    }

    this.steps.push(step);
  }

  private selectStep(step: ModalStepComponent) {
    this.steps.forEach(s => s.active = false);
    step.active = true;
  }

  private getActiveStepIndex(): number {
    return this.steps.findIndex(s => {
      return s.active;
    });
  }

  showPrevious(): boolean {
    return this.getActiveStepIndex() > 0;
  }

  showNext(): boolean {
    return this.getActiveStepIndex() != this.steps.length - 1;
  }

  showConfirm(): boolean {
    return this.getActiveStepIndex() === this.steps.length - 1;
  }

  isStepValid(): boolean {
    return this.steps[this.getActiveStepIndex()].valid;
  }

  previous(): void {
    this.selectStep(this.steps[this.getActiveStepIndex() - 1]);
  }

  next(): void {
    this.selectStep(this.steps[this.getActiveStepIndex() + 1]);
  }

  navigate(step: ModalStepComponent) {
    if (step.valid) {
      this.selectStep(step);
    }
  }
}
