export default {
  WashType0: { icon: '/assets/quick-tools-icons/bath.svg' },
  WashType1: { icon: '/assets/quick-tools-icons/basin.svg' },
  WashType2: { icon: '/assets/quick-tools-icons/bed-bath.svg' },
  WashType3: { icon: '/assets/quick-tools-icons/shower.svg' },
  WashType4: { icon: '/assets/quick-tools-icons/shave.svg' },
  WashType5: { icon: '/assets/quick-tools-icons/hair-wash.svg' },
  WashType6: { icon: '/assets/quick-tools-icons/personal-hygiene.svg' },
  WashType7: { icon: '/assets/quick-tools-icons/oral-health.svg' },
  Position0: { icon: 'assets/record/turning/turn_r.svg' },
  Position1: { icon: 'assets/record/turning/turn_u.svg' },
  Position2: { icon: 'assets/record/turning/turn_m.svg' },
  Position3: { icon: 'assets/record/turning/turn_b.svg' },
  Position4: { icon: 'assets/record/turning/turn_p.svg' },
  Position5: { icon: 'assets/record/turning/turn_l.svg' },
  PortionSize0: { icon: '/assets/quick-tools-icons/small.svg' },
  PortionSize1: { icon: '/assets/quick-tools-icons/medium.svg' },
  PortionSize2: { icon: '/assets/quick-tools-icons/large.svg' },
  StoolType0: { icon: '/assets/record/bowel-bladder/stool_type_1.png'},
  StoolType1: { icon: '/assets/record/bowel-bladder/stool_type_2.png'},
  StoolType2: { icon: '/assets/record/bowel-bladder/stool_type_3.png'},
  StoolType3: { icon: '/assets/record/bowel-bladder/stool_type_4.png'},
  StoolType4: { icon: '/assets/record/bowel-bladder/stool_type_5.png'},
  StoolType5: { icon: '/assets/record/bowel-bladder/stool_type_6.png'},
  StoolType6: { icon: '/assets/record/bowel-bladder/stool_type_7.png' },
  UrineColour0: { icon: '/assets/record/bowel-bladder/BOWEL_URINE_COLOUR_OPTIONS_1.png'},
  UrineColour1: { icon: '/assets/record/bowel-bladder/BOWEL_URINE_COLOUR_OPTIONS_2.png'},
  UrineColour2: { icon: '/assets/record/bowel-bladder/BOWEL_URINE_COLOUR_OPTIONS_3.png'},
  UrineColour3: { icon: '/assets/record/bowel-bladder/BOWEL_URINE_COLOUR_OPTIONS_4.png'},
  UrineColour4: { icon: '/assets/record/bowel-bladder/BOWEL_URINE_COLOUR_OPTIONS_5.png'},
  UrineColour5: { icon: '/assets/record/bowel-bladder/BOWEL_URINE_COLOUR_OPTIONS_6.png'},
  UrineColour6: { icon: '/assets/record/bowel-bladder/BOWEL_URINE_COLOUR_OPTIONS_7.png'},
  UrineColour7: { icon: '/assets/record/bowel-bladder/BOWEL_URINE_COLOUR_OPTIONS_8.png'},
};
