import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {BankCardComponent} from "@shared/components";
import {ScrollTrackingService} from "@core/services";

@Component({
  selector: 'app-bank',
  templateUrl: './bank.component.html',
  styleUrls: ['./bank.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BankComponent implements OnInit {

  items: BankCardComponent[] = [];
  loadOnScrollThreshold = 500;
  debounceTime = 300;
  scrollTimeout = null;
  searchTerm: string = '';
  filterOpen = false;
  calendarOpen = false;
  sidebarOpen = false;

  @Input()
  title: string;

  @Input()
  availableItemsCount: number = 0;

  @Input()
  filterEnabled = false;

  @Input()
  calendarEnabled = false;

  @Input()
  showSearchBar = true;

  @Input()
  showSideBar = true;

  @Output()
  onScroll: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onSearch: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  onFilterToggled: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onCalendarToggled: EventEmitter<void> = new EventEmitter<void>();

  constructor(private scrollTrackingService: ScrollTrackingService) { }

  ngOnInit() {
  }

  addItem(item: BankCardComponent): void {
    this.items.push(item);
  }

  scroll(e): void {
    this.scrollTrackingService.setScrollPosition(e.target.scrollTop);
    const leftToScroll = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight;

    if (leftToScroll < this.loadOnScrollThreshold
      && !this.scrollTrackingService.scrollingUp()
      && this.availableItemsCount !== this.items.length) {
      if (this.scrollTimeout) { clearTimeout(this.scrollTimeout); }
      this.scrollTimeout = setTimeout(() => {
        this.onScroll.emit();
      }, this.debounceTime);
    }
  }

  search(term: string): void {
    this.searchTerm = term;
    this.onSearch.emit(term);
  }

  toggleFilter(enabled: boolean): void {
    this.filterOpen = enabled;
    this.calendarOpen = false;
    this.onFilterToggled.emit();
    this.sidebarOpen = this.filterOpen;
  }

  toggleCalendar(enabled: boolean): void {
    this.calendarOpen = enabled;
    this.filterOpen = false;
    this.onCalendarToggled.emit();
    this.sidebarOpen = this.calendarOpen;
  }

}
