import { Component, OnInit } from '@angular/core';
import { faArrowCircleRight } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-close-drawer-button',
  templateUrl: './close-drawer-button.component.html',
  styleUrls: ['./close-drawer-button.component.scss']
})
export class CloseDrawerButtonComponent implements OnInit {
  faArrowCircleRight = faArrowCircleRight;

  constructor() { }

  ngOnInit() {
  }

}
