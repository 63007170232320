import {Component, OnInit} from '@angular/core';
import {UserService} from "@core/services";
import {UpdatePassword, User} from "@shared/types/models";
import {ToastrService} from "ngx-toastr";
import {NgxSmartModalService} from "ngx-smart-modal";
import { faTimes } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-update-password-modal',
  templateUrl: './update-password-modal.component.html',
  styleUrls: ['./update-password-modal.component.scss']
})
export class UpdatePasswordModalComponent implements OnInit {

  faTimes = faTimes;
  passwordStrength = 0;

  user: User;
  setPasswordModel: any;

  constructor(public ngxSmartModalService: NgxSmartModalService,
              public userService: UserService,
              public toastr: ToastrService) { }

  ngOnInit() {
    this.user = this.ngxSmartModalService.getModalData('changePassword').user;

    this.setPasswordModel = {
      email: this.user.email
    };
  }

  close(event): void {
    event.stopPropagation();
    const modal = this.ngxSmartModalService.getModal('changePassword');
    modal.removeData();
    modal.close();
  }

  updatePassword(event): void {
    const model = new UpdatePassword();
    model.email = this.setPasswordModel.email;
    model.password = this.setPasswordModel.password;

    this.userService.updatePassword(model)
      .subscribe(_ => {
        this.close(event);
        this.toastr.success('User password updated successfully.', 'Success!');
      });
  }

  setPasswordStrength(strength): void {
    this.passwordStrength = strength;
  }

}
