import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-yes-no-input',
  templateUrl: './yes-no-input.component.html',
  styleUrls: ['./yes-no-input.component.scss']
})
export class YesNoInputComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  subTitle: string;

  @Input()
  value: boolean;

  @Output()
  valueChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() { }

  updateValue(value: boolean): void {
    this.value = value;
    this.valueChanged.emit(value);
  }

}
