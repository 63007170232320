import { Component, Input, OnInit } from '@angular/core';
import { ListItem } from "../../../types/models/listItem.model";
import { IconDefinition } from "@fortawesome/fontawesome-common-types/index";

@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss']
})
export class ItemListComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  introduction: string;

  @Input()
  items: any[] = [];

  @Input()
  propertyName: string;

  @Input()
  propertyPrefix: string;

  @Input()
  propertyImage: string;

  @Input()
  defaultImage: string;

  @Input()
  defaultIcon: IconDefinition;

  @Input()
  defaultColour: string;

  constructor() { }

  ngOnInit() {
  }

  createItem(item: any): void {
    this.items.push(item);
  }

}
