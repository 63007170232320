import {Injectable} from "@angular/core";
//import {UserService} from "@core/services";
import {AbstractControl, ValidationErrors} from "@angular/forms";
import {Observable, of} from "rxjs";
import {debounceTime, distinctUntilChanged, map, switchMapTo, take, tap} from "rxjs/operators";

@Injectable()
export class EmailValidator {
  constructor(/*public userService: UserService*/) {
  }

  checkEmail(control: AbstractControl): Observable<ValidationErrors | null> {
    if (!control.valueChanges || control.pristine) {
      return of(null);
    } else {
      /*return control.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        take(1),
        switchMapTo(this.userService.emailAvailable(control.value)),
        tap(() => control.markAllAsTouched()),
        map(data => {
          return data == false ? { emailInUse: true } : null;
        })
      );*/
      return of(null);
    }
  }

}
