import {Component, Input, OnInit} from '@angular/core';
import {SubSectionComponent} from '../sub-section.component';
import {ProgressStatus} from '@shared/types/enumerations';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss']
})
export class StepComponent implements OnInit {

  @Input()
  active = false;

  @Input()
  valid = false;

  @Input()
  number: number;

  constructor(public subSection: SubSectionComponent) {
    subSection.addStep(this);
  }

  ngOnInit() {}

  setActive(): void {
    this.active = true;
  }

  setInactive(): void {
    this.active = false;
  }

  getStatus(): ProgressStatus {
    const stepIndex = this.number;
    const subSectionIndex = this.subSection.number;
    const sectionIndex = this.subSection.section.number;
    const progress = this.subSection.section.sectionContainer.progress;
    const section = progress.sections[sectionIndex];
    if (section != null) {
      const subSection = section.subSections[subSectionIndex];
      if (subSection != null) {
        if (subSection.steps != null && subSection.steps.length > 0) {
          return subSection.steps[stepIndex].status;
        }
      }
    }
    return ProgressStatus.Unseen;
  }
}
