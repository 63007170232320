import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { faTimesCircle, faCheckCircle } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-action-list-item',
  templateUrl: './action-list-item.component.html',
  styleUrls: ['./action-list-item.component.scss']
})
export class ActionListItemComponent implements OnInit {
  faTimesCircle = faTimesCircle;
  faCheckCircle = faCheckCircle;

  @Input()
  item: any = { description: ''};

  @Input()
  new = false;

  @Output()
  itemRemoved: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  itemCreated: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  createItem(): void {
    this.itemCreated.emit(this.item);
    this.item = { summary: ''};
  }

  removeItem(): void {
    this.itemRemoved.emit(this.item);
  }
}
