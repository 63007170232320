import {Deserializable} from "@shared/types/interfaces/deserializable.interface";

export class Address implements Deserializable {
    addressOne: string;
    addressTwo: string;
    addressThree: string;
    addressFour: string;
    townCity: string;
    county: string;
    postcode: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

