import {Component, Input, OnInit} from '@angular/core';
import {faCheckCircle, faCircle} from '@fortawesome/pro-solid-svg-icons';
import {faCircle as faLineCircle} from '@fortawesome/pro-regular-svg-icons';
import {ProgressStatus} from '@shared/types/enumerations';


@Component({
  selector: 'app-progress-item',
  templateUrl: './progress-item.component.html',
  styleUrls: ['./progress-item.component.scss']
})
export class ProgressItemComponent implements OnInit {
  faLineCircle = faLineCircle;
  faCircle = faCircle;
  faCheckCircle = faCheckCircle;

  @Input()
  title: string;

  @Input()
  status: ProgressStatus = ProgressStatus.Unseen;

  @Input()
  active: boolean;

  @Input()
  placeholder: boolean;

  constructor() { }

  ngOnInit() { }

}
