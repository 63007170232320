import {Component, Input, OnInit} from '@angular/core';
import {ToggleGroupComponent} from '../toggle-group.component';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  value: any;

  @Input()
  selected: boolean;

  constructor(public toggleGroup: ToggleGroupComponent) {
    toggleGroup.addItem(this);
  }

  ngOnInit() {
  }

  toggleSelected(): void {
    this.selected = true;
    this.toggleGroup.itemSelected(this);
  }

}
