import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { ConfigurationFilter } from '@shared/filters';
import { Observable, BehaviorSubject } from 'rxjs';
import { Configuration } from '@shared/types/models';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService extends ApiService<Configuration> {

  private residentApplicationUpdatedSource = new BehaviorSubject<Configuration>(null);
  residentApplicationUpdated$ = this.residentApplicationUpdatedSource.asObservable();

  changeConfiguration(residentApplication: Configuration) {
    this.residentApplicationUpdatedSource.next(residentApplication);
  }

  public residentApplication$: Observable<Configuration>;

  constructor(protected httpClient: HttpClient) {
    super(httpClient, 'configurations');
  }

  public getFilter(): ConfigurationFilter {
    return new ConfigurationFilter();
  }

}
