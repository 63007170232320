import {Component, Input, OnInit, SimpleChanges} from '@angular/core';

import { faExpandAlt, faCompressAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-expand-content',
  templateUrl: './expand-content.component.html',
  styleUrls: ['./expand-content.component.scss']
})
export class ExpandContentComponent implements OnInit {

  faExpandAlt = faExpandAlt;
  faCompressAlt = faCompressAlt;

  @Input()
  title: string;

  @Input()
  content: string;

  @Input()
  characterLimit: number;

  @Input()
  showText: string = "Show";

  @Input()
  hideText: string = "Hide";

  @Input()
  forceCollapse: boolean = false;

  requiresCollapse = false;
  expanded = false;

  summaryContent: string;

  constructor() { }

  ngOnInit() {
    this.setup();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setup();
  }

  setup(): void {
    if (this.content && this.content.length > this.characterLimit) {
      this.requiresCollapse = true;
      if (this.characterLimit > 0) {
        this.summaryContent = this.content.substring(0, this.characterLimit) + " ...";
      }
    } else {
      this.requiresCollapse = this.forceCollapse;
    }
  }

  toggleExpand(event): void {
    event.stopPropagation();
    if (this.requiresCollapse) {
      this.expanded = !this.expanded;
    }
  }

}
