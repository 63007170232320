import {NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {ErrorHandler} from "protractor/built/exitCodes";
import {RaygunErrorHandler} from "../app.raygun.setup.js";
import * as fromComponents from './components';
import {HeaderComponent, NavigationComponent} from "./components";
import {RouterModule} from "@angular/router";
import {FormsModule} from "@angular/forms";
import {SharedModule} from "@shared/shared.module";
import {HttpErrorInterceptor} from "@core/interceptors/http-error/http-error.interceptor";
import {HttpAuthInterceptor} from "@core/interceptors/http-auth/http-auth.interceptor";

@NgModule({
  declarations: [
    ...fromComponents.components
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    SharedModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: RaygunErrorHandler
    },
    DatePipe,
    DecimalPipe
  ],
  exports: [
    HeaderComponent,
    NavigationComponent,
    FormsModule
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. You should import CoreModule in the AppModule only.');
    }
  }
}
