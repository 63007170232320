import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { SectionComponent } from './section/section.component';
import { SubSectionComponent } from './section/sub-section/sub-section.component';
import { faEllipsisV, faUser } from "@fortawesome/pro-solid-svg-icons";
import { ProgressStatus } from "@shared/types/enumerations";

@Component({
  selector: 'app-section-container',
  templateUrl: './section-container.component.html',
  styleUrls: ['./section-container.component.scss']
})
export class SectionContainerComponent implements OnInit {
  sections: SectionComponent[] = [];
  currentSection: SectionComponent;

  faEllipsisV = faEllipsisV;
  faUser = faUser;
  quickMenuActive = false;
  showSidebar = false;

  @Input()
  progress: any;

  @Input()
  percentageComplete: number;

  @Input()
  menu: any;

  @Input()
  sidePanel: any;

  @Output()
  menuItemClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onSectionChange: EventEmitter<SectionComponent> = new EventEmitter<SectionComponent>();

  @ViewChild('mainContent', { static: false }) mainContent: ElementRef;

  ngOnInit() {
    if (this.sections.length > 0) {
      this.setActiveSection(this.sections[0], this.sections[0].subSections[0]);
    }
  }

  toggleQuickMenu(e: Event): void {
    this.quickMenuActive = !this.quickMenuActive;
    e.stopPropagation();
  }

  hideQuickMenu(e: Event): void {
    this.quickMenuActive = false;
    e.stopPropagation();
  }

  addSection(section: SectionComponent) {
    this.sections.push(section);
  }

  setActiveSection(section: SectionComponent, subSection: SubSectionComponent) {
    this.sections.forEach(s => {
      s.setInactive();
      s.subSections.forEach(ss => ss.setInactive());
    });
    section.setActive(subSection);
    this.currentSection = section;
    this.onSectionChange.emit(section);
  }

  nextSection(): void {
    const index = this.sections.findIndex(step => step === this.currentSection);
    if (index < (this.sections.length - 1)) {
      this.setActiveSection(this.sections[index + 1], this.sections[index + 1].subSections[0]);
    }
  }

  previousSection(): void {
    const index = this.sections.findIndex(step => step === this.currentSection);
    if (index > 0) {
      this.setActiveSection(this.sections[index - 1], this.sections[index - 1].subSections[0]);
      this.currentSection.setToFinalSubSection();
    }
  }

  selectSection(section: SectionComponent): void {
    if (section.subSections[0].getStatus() !== ProgressStatus.Unseen)
      this.setActiveSection(section, section.subSections[0]);
  }

  selectSubSection(section: SectionComponent, subSection: SubSectionComponent): void {
    if (subSection.getStatus() !== ProgressStatus.Unseen)
      this.setActiveSection(section, subSection);
  }

  toggleSidebar(): void {
    this.showSidebar = !this.showSidebar;
  }

  sortSubSections(subSections: SubSectionComponent[]): SubSectionComponent[] {
    return subSections.sort((a, b) => (a.number > b.number) ? 1 : -1);
  }

  scrollToTop(): void {
    this.mainContent.nativeElement.scrollTop = 0;
  }

}
