import { Enumeration } from './enumeration.model';
import { CalendarEventType } from '@shared/types/enumerations';

export class CalendarEvent {
  id: string;
  title: string;
  start: string;
  end: string;
  type: Enumeration<CalendarEventType>;
  residentApplicationId: string;
}
