import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ConversionService} from "@core/services";


@Component({
  selector: 'app-circumference-input',
  templateUrl: './circumference-input.component.html',
  styleUrls: ['./circumference-input.component.scss']
})
export class CircumferenceInputComponent implements OnInit {

  currentCircumferenceUnits = 'metric';
  currentCircumference: any;

  @Input()
  circumferenceInCentimeters: number = 0;

  @Output()
  valueChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(private conversionService: ConversionService) { }

  ngOnInit() {
    this.currentCircumference = {
      in: 0,
      cm: this.circumferenceInCentimeters
    };
    this.calculateImperialCircumference();
  }

  calculateMetricCircumference(): void {
    this.currentCircumference.cm = this.conversionService.inchesToCentimetres(this.currentCircumference.in);
    this.circumferenceInCentimeters = this.currentCircumference.cm;
    this.valueChanged.emit(this.circumferenceInCentimeters);
  }

  calculateImperialCircumference(): void {
    this.currentCircumference.in = this.conversionService.centimetresToInches(this.currentCircumference.cm);
    this.circumferenceInCentimeters = this.currentCircumference.cm;
    this.valueChanged.emit(this.circumferenceInCentimeters);
  }

  changeCircumferenceUnits(newUnit: string): void {
    this.currentCircumferenceUnits = newUnit;
  }

}
