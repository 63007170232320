import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faEllipsisV} from "@fortawesome/pro-regular-svg-icons";

@Component({
  selector: 'app-context-menu-toggle',
  templateUrl: './context-menu-toggle.component.html',
  styleUrls: ['./context-menu-toggle.component.scss']
})
export class ContextMenuToggleComponent implements OnInit {
  faEllipsisV = faEllipsisV;

  @Input()
  open = false;

  @Output()
  toggled: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  toggle(): void {
    this.open = !this.open;
    this.toggled.emit(this.open)
  }

}
