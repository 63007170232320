import { Injectable } from '@angular/core';
import {ApiService} from '../api/api.service';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {CreateUser, UpdatePassword, User} from "@shared/types/models";
import {UserFilter} from "@shared/filters";
import { Role } from "@shared/types/interfaces/role.interface";

@Injectable({
  providedIn: 'root'
})
export class UserService extends ApiService<User> {

  private userCreatedSource = new BehaviorSubject<string>(null);
  userCreated$ = this.userCreatedSource.asObservable();

  userCreatedEvent(input) {
    this.userCreatedSource.next(input);
  }

  private userUpdatedSource = new BehaviorSubject<User>(null);
  userUpdated$ = this.userUpdatedSource.asObservable();

  userUpdatedEvent(input) {
    this.userUpdatedSource.next(input);
  }

  constructor(protected httpClient: HttpClient) {
    super(httpClient, 'users');
  }

  public getFilter(): UserFilter {
    return new UserFilter();
  }

  setActiveLocation$(locationId: string): Observable<any> {
    return this.httpClient.put(this.url + '/location/' + locationId , this.httpOptions);
  }

  emailAvailable(email: string): Observable<any> {
    return this.httpClient.get<any>(`${this.url}/email/${email}`);
  }

  create$(model: CreateUser): Observable<User> {
    return this.httpClient.post<User>(this.url, model, this.httpOptions);
  }

  updatePassword(model: UpdatePassword): Observable<any> {
    return this.httpClient.put(this.url + '/password', model, this.httpOptions);
  }

  activate$(userId: string): Observable<any> {
    const activateUrl = `${this.url}/${userId}/activate`;
    return this.httpClient.post(activateUrl, {}, this.httpOptions);
  }

  deactivate$(userId: string): Observable<any> {
    const activateUrl = `${this.url}/${userId}/activate`;
    return this.httpClient.delete(activateUrl, this.httpOptions);
  }

  updateLastLogin$(userId: string, lastLogin: any): Observable<any> {
    const url = `${this.url}/lastlogin`;
    return this.httpClient.put(url, { id: userId,  lastLogin: lastLogin }, this.httpOptions);
  }

  getRoles$(): Observable<Role[]> {
    return this.httpClient.get<Role[]>(this.url + '/roles', this.httpOptions);
  }
}
