import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {EnumerationService} from "@core/services";
import {faBell} from "@fortawesome/pro-regular-svg-icons";
import {RepeatSchedule} from "@shared/types/interfaces/repeatSchedule.interface";

@Component({
  selector: 'app-review-schedule',
  templateUrl: './review-schedule.component.html',
  styleUrls: ['./review-schedule.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReviewScheduleComponent implements OnInit {
  faBell = faBell;

  unitsOfTime: any[];
  editMode = false;

  reviewScheduleBackup: RepeatSchedule;

  @Input()
  reviewSchedule: RepeatSchedule;

  @Output()
  reviewScheduleUpdated: EventEmitter<RepeatSchedule | null> = new EventEmitter<RepeatSchedule | null>();

  constructor(public enumerationService: EnumerationService) { }

  ngOnInit() {
    this.enumerationService.getUnitsOfTime$()
      .subscribe(response => {
        this.unitsOfTime = response.body;
      });
  }

  isSelected(selection): boolean {
    if (selection == 'never') {
      return !this.reviewSchedule;
    }

    if (selection == 'daily') {
      return this.isDaily();
    }

    if (selection == 'weekly') {
      return this.isWeekly();
    }

    if (selection == 'monthly') {
      return this.isMonthly();
    }

    if (selection == 'custom') {
      return this.isCustom();
    }

    return false;
  }

  public isCustom() {
    return this.reviewSchedule != null
      && !this.isDaily()
      && !this.isWeekly()
      && !this.isMonthly();
  }

  private isWeekly() {
    return this.reviewSchedule
      && (this.reviewSchedule.unit && this.reviewSchedule.unit.value == 3)
      && (this.reviewSchedule.duration && this.reviewSchedule.duration == 1);
  }

  private isDaily() {
    return this.reviewSchedule
      && (this.reviewSchedule.unit && this.reviewSchedule.unit.value == 2)
      && (this.reviewSchedule.duration && this.reviewSchedule.duration == 1);
  }

  public isMonthly() {
    return this.reviewSchedule
      && (this.reviewSchedule.unit && this.reviewSchedule.unit.value == 4)
      && (this.reviewSchedule.duration && this.reviewSchedule.duration == 1);
  }

  removeSchedule(): void {
    this.reviewSchedule = null;
    this.updateReviewSchedule();
  }

  setDaily(): void {
    this.reviewSchedule = {
      unit: this.unitsOfTime.find(ut => ut.value == 2),
      duration: 1,
      repeat: false,
      isStart: true
    };
    this.updateReviewSchedule();
  }

  setWeekly(): void {
    this.reviewSchedule = {
      unit: this.unitsOfTime.find(ut => ut.value == 3),
      duration: 1,
      repeat: false,
      isStart: true
    };
    this.updateReviewSchedule();
  }

  setMonthly(): void {
    this.reviewSchedule = {
      unit: this.unitsOfTime.find(ut => ut.value == 4),
      duration: 1,
      repeat: false,
      isStart: true
    };
    this.updateReviewSchedule();
  }

  setCustom(): void {
    this.reviewSchedule = {
      unit: this.unitsOfTime.find(ut => ut.value == 0),
      duration: 1,
      repeat: false,
      isStart: true
    };
  }

  enterEditMode(): void {
    this.reviewScheduleBackup = Object.assign({}, this.reviewSchedule);
    this.editMode = true;
  }

  updateCustomSchedule(): void {
    this.editMode = false;
    this.reviewSchedule = Object.assign({}, this.reviewScheduleBackup);
    this.updateReviewSchedule();
  }

  updateReviewSchedule(): void {
    this.reviewScheduleUpdated.emit(this.reviewSchedule);
  }

  cancelCustomScheduleChanges(): void {
    this.editMode = false;
  }

  isScheduleValid(): boolean {
    return this.reviewScheduleBackup.unit != null && this.reviewScheduleBackup.duration > 0;
  }
}
