import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { faChevronUp, faChevronDown } from "@fortawesome/pro-light-svg-icons";

@Component({
  selector: 'app-number-select',
  templateUrl: './number-select.component.html',
  styleUrls: ['./number-select.component.scss']
})
export class NumberSelectComponent implements OnInit {

  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;

  @Input()
  value: number;

  @Input()
  title: string;

  @Input()
  subTitle: string;

  @Output()
  valueUpdated: EventEmitter<number> = new EventEmitter<number>();
  
  constructor() { }

  ngOnInit() {  }

  up(): void {
    this.value++;
    this.valueUpdated.emit(this.value);
  }

  down(): void {
    if (this.value > 0) {
      this.value--;
      this.valueUpdated.emit(this.value);
    }
  }

}
