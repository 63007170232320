import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  baseUrl: string;
  apiKey = '3OMQkWtATk6Zmv0nG31A-Q25603';

  constructor(private httpClient: HttpClient) {
    this.baseUrl = 'https://api.getaddress.io/find/';
  }

  lookup$(postcode: string): Observable<any> {
    const params = new HttpParams()
      .set('api-key', this.apiKey)
      .set('expand', 'true');
    const url = this.baseUrl + postcode;
    return this.httpClient.get<any>(url, { params })
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
}
