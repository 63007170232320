import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api/api.service';
import { CalendarEventFilter } from '@shared/filters';
import { CalendarEvent } from '@shared/types/models';

@Injectable({
  providedIn: 'root'
})

export class CalendarEventService extends ApiService<CalendarEvent> {

  public filter = new CalendarEventFilter();

  constructor(protected httpClient: HttpClient) {
    super(httpClient, 'calendarevents');
  }

  getFilter(): CalendarEventFilter {
    return this.filter;
  }
}
