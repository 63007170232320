import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faChevronLeft, faChevronRight} from "@fortawesome/pro-regular-svg-icons";

@Component({
  selector: 'app-pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.scss']
})
export class PagerComponent implements OnInit {

  @Input()
  itemCount: number;

  @Output()
  itemChanged: EventEmitter<number> = new EventEmitter<number>();

  currentItem = 1;

  faChevronRight = faChevronRight;
  faChevronLeft = faChevronLeft;

  constructor() { }

  ngOnInit() {
  }

  previousDisabled(): boolean {
    return this.currentItem === 1;
  }

  nextDisabled(): boolean {
    return this.currentItem === this.itemCount;
  }

  previous(): void {
    if (this.currentItem !== 1) {
      this.currentItem--;
    }
    this.itemChanged.emit(this.currentItem - 1);
  }

  next(): void {
    if (this.currentItem !== this.itemCount) {
      this.currentItem++;
    }
    this.itemChanged.emit(this.currentItem - 1);
  }

}
