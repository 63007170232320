import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToggleButtonIconGroupComponent} from '../toggle-button-icon-group.component';

@Component({
  selector: 'app-toggle-button-icon',
  templateUrl: './toggle-button-icon.component.html',
  styleUrls: ['./toggle-button-icon.component.scss']
})
export class ToggleButtonIconComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  subtitle: string;

  @Input()
  image: string;

  @Input()
  value: any;

  @Input()
  selected: boolean;

  constructor(public toggleButtonGroup: ToggleButtonIconGroupComponent) {
    toggleButtonGroup.addItem(this);
  }

  ngOnInit() {
  }

  itemSelected(): void {
    this.selected = true;
    this.toggleButtonGroup.itemSelected(this);
  }
}
