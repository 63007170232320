// // Local
// export const environment = {
//   production: false,
//   apiUrl: 'https://localhost:7101',
//   raygunApiKey: '9GMujqBoFXBunDMoGyke2w'
// };
//
// // Development
// export const environment = {
//   production: false,
//   apiUrl: 'https://kareinn-dev-rhc.azurewebsites.net/api',
//   raygunApiKey: '9GMujqBoFXBunDMoGyke2w'
// };

// Production
export const environment = {
  production: true,
  apiUrl: 'https://kareinn-rhc-prod.azurewebsites.net/api',
  raygunApiKey: '9GMujqBoFXBunDMoGyke2w'
};
