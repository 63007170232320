import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {

  constructor() {
    this.opened = false;
  }

  opened = false;

  public static getCurrentPath() {
    return window.location.pathname;
  }

  toggleSidebar() {
    this.opened = !this.opened;
  }

  showHeader(): boolean {
    const pathname = AppComponent.getCurrentPath();
    return pathname !== '/login' && pathname !== '/' && pathname.indexOf('summary') < 0;
  }
}
