import {IFilter} from "./filter.interface";
import {FilterCondition} from "../../../../../types/interfaces/filterCondition.interface";
import {Input} from "@angular/core";
import {FilterGroupComponent} from "../filter-group.component";

export abstract class Filter implements IFilter {
  @Input()
  filterCondition: FilterCondition;

  isValid: boolean;

  protected constructor(public filterGroup: FilterGroupComponent) {
    this.isValid = false;
    filterGroup.addFilter(this);
  }

  protected setAsValid() {
    this.filterCondition.isValid = true;
    this.filterCondition.query = this.getQuery();
    this.filterCondition.summary = this.getSummary();
  }

  abstract getSummary(): string;

  abstract getQuery(): any;

}
