import { LocationFilter } from "./location.filter";
import { ResidentApplicationFilter } from "./residentApplication.filter";
import { CalendarEventFilter } from "./calendarEvent.filter";
import { UserFilter } from "./user.filter";
import { AccountFilter } from "./account.filter";
import { ConfigurationFilter } from "./configuration.filter";

export const filters: any[] = [
  LocationFilter,
  ResidentApplicationFilter,
  CalendarEventFilter,
  UserFilter,
  AccountFilter,
  ConfigurationFilter];

export * from './location.filter';
export * from './residentApplication.filter';
export * from './calendarEvent.filter';
export * from './user.filter';
export * from './account.filter';
export * from './configuration.filter';
