type StandardEnumValue = string | number;

export class Enumeration<T extends StandardEnumValue> {
  value: T;
  displayName: string;

  constructor(value: T, displayName: string) {
    this.value = value;
    this.displayName = displayName;
  }
}
