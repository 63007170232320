import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import { Document, DocumentUpload} from '../../../shared/types/models/document.model';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  private readonly url: string;

  constructor(private httpClient: HttpClient) {
    this.url = environment.apiUrl + '/documents';
  }

  add$(model: DocumentUpload): Observable<any> {
    return this.httpClient.post(this.url, model, this.httpOptions);
  }

  delete$(id: string): Observable<any> {
    return this.httpClient.delete(this.url + `/${id}`, this.httpOptions);
  }
}
