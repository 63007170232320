import {HeaderComponent} from "@core/components/header/header.component";
import {NavigationComponent} from "@core/components/navigation/navigation.component";
import {NavMenuComponent} from "@core/components/navigation/nav-menu/nav-menu.component";
import {NavLinkComponent} from "@core/components/navigation/nav-menu/nav-link/nav-link.component";
import {NavLinkGroupComponent} from "@core/components/navigation/nav-menu/nav-link-group/nav-link-group.component";
import {HeaderMenuComponent} from "@core/components/header/header-menu/header-menu.component";

export const components: any[] = [
  HeaderComponent,
  HeaderMenuComponent,
  NavigationComponent,
  NavMenuComponent,
  NavLinkComponent,
  NavLinkGroupComponent];

export * from './header/header.component';
export * from './header/header-menu/header-menu.component';
export * from './navigation/navigation.component';
export * from './navigation/nav-menu/nav-menu.component';
export * from './navigation/nav-menu/nav-link/nav-link.component';
export * from './navigation/nav-menu/nav-link-group/nav-link-group.component';
