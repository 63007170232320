import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { faTimes, faBars } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent implements OnInit {
  faBars = faBars;
  faTimes = faTimes;

  @Input()
  menuOpen = false;

  @Output()
  onToggleMenu: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onToggleUserMenu: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

  toggleMenu(): void {
    this.onToggleMenu.emit();
  }

  toggleUserMenu(): void {
    this.onToggleUserMenu.emit();
  }

}
