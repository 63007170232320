import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Document, DocumentUpload } from '@shared/types/models';
import { NgxSpinnerService } from "ngx-spinner";
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  faTimesCircle = faTimesCircle;

  @Input()
  multiple = false;

  @Input()
  enableDelete = false;

  @Input()
  documents: Document[];

  @Output()
  fileUploaded: EventEmitter<DocumentUpload> = new EventEmitter<DocumentUpload>();

  @Output()
  fileDeleted: EventEmitter<string> = new EventEmitter<string>();

  constructor(public spinnerService: NgxSpinnerService) { }

  ngOnInit() { }

  uploadFile(event) {
    for (let index = 0; index < event.length; index++) {
      const element = event[index];

      var filesize = ((element.size / 1024) / 1024);

      if (filesize > 8)
        continue;

      const reader = new FileReader();
      this.spinnerService.show();
      reader.readAsDataURL(element);
      reader.onloadend = (_) => {
        const file = new DocumentUpload(
          element.name,
          element.type,
          reader.result
        );
        this.fileUploaded.emit(file);
        this.spinnerService.hide();
      };
    }
  }

  deleteFile(id: string): void {
    this.fileDeleted.emit(id);
  }

  downloadFile(url: string, event: any): void {
    event.preventDefault();
    window.open(url);
  }
}
