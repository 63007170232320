import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ToggleButtonIconComponent} from './toggle-button-icon/toggle-button-icon.component';

@Component({
  selector: 'app-toggle-button-icon-group',
  templateUrl: './toggle-button-icon-group.component.html',
  styleUrls: ['./toggle-button-icon-group.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ToggleButtonIconGroupComponent implements OnInit {

  items: ToggleButtonIconComponent[] = [];

  @Output()
  selectedItemChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {}

  addItem(item: ToggleButtonIconComponent): void {
    this.items.push(item);
  }

  itemSelected(item: ToggleButtonIconComponent): void {
    this.items.forEach(x => x.selected = false);
    item.selected = true;
    this.selectedItemChanged.emit(item);
  }
}
