import {Component, Input, OnInit} from '@angular/core';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-check-button',
  templateUrl: './check-button.component.html',
  styleUrls: ['./check-button.component.scss']
})
export class CheckButtonComponent implements OnInit {
  faCheckCircle = faCheckCircle;

  @Input()
  disabled = false;

  constructor() { }

  ngOnInit() {
  }

}
