import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgxSpinnerModule } from "ngx-spinner";
import { SidebarModule } from "ng-sidebar";
import { ToastrModule } from "ngx-toastr";
import { ClickOutsideModule } from "ng-click-outside";
import { CarouselModule } from "ngx-owl-carousel-o";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";

import * as fromDirectives from './directives';
import * as fromComponents from './components';
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { ConfirmationModalComponent, UpdatePasswordModalComponent } from './components';
import { EmailValidatorDirective } from "@shared/validators/email-validator.directive";
import { MustMatchValidatorDirective } from "@shared/validators/must-match-validator.directive";
import { EmailValidator } from "@shared/validators/email.validator";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { PasswordStrengthMeterModule } from "angular-password-strength-meter";
import { NgxPermissionsModule } from "ngx-permissions";
import { ContextMenuComponent } from './components/controls/context-menu/context-menu.component';
import { ContextMenuItemComponent } from './components/controls/context-menu/context-menu-item/context-menu-item.component';
import { ContextMenuToggleComponent } from './components/controls/context-menu/context-menu-toggle/context-menu-toggle.component';
import { ReminderIndicatorComponent } from './components/controls/reminder-indicator/reminder-indicator.component';
import { NewUserModalComponent } from "@shared/components/user/new-user-modal/new-user-modal.component";
import { ModalStepComponent } from './components/modals/multi-stage-modal/modal-step/modal-step.component';
import {ScoringMatrixCalculation} from "@shared/calculations/scoring-matrix-calculation";

@NgModule({
  declarations: [
    ...fromDirectives.directives,
    ...fromComponents.components,
    EmailValidatorDirective,
    MustMatchValidatorDirective,
    ContextMenuComponent,
    ContextMenuItemComponent,
    ContextMenuToggleComponent,
    ReminderIndicatorComponent,
    NewUserModalComponent,
    ModalStepComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    FontAwesomeModule,
    NgxSpinnerModule,
    SidebarModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-left',
      progressBar: true,
      timeOut: 3000,
      maxOpened: 1,
      autoDismiss: true
    }),
    ClickOutsideModule,
    CarouselModule,
    NgxSmartModalModule.forRoot(),
    NgxChartsModule,
    NgxDatatableModule,
    TooltipModule.forRoot(),
    PasswordStrengthMeterModule,
    NgxPermissionsModule.forChild()
  ],
  exports: [
    ...fromDirectives.directives,
    ...fromComponents.components,
    FormsModule,
    FontAwesomeModule,
    NgxSpinnerModule,
    SidebarModule,
    ToastrModule,
    ClickOutsideModule,
    CarouselModule,
    NgxSmartModalModule,
    NgxChartsModule,
    NgxDatatableModule,
    EmailValidatorDirective,
    MustMatchValidatorDirective,
    TooltipModule,
    PasswordStrengthMeterModule,
    NgxPermissionsModule,
    ContextMenuComponent,
    ContextMenuItemComponent,
    ReminderIndicatorComponent,
    NewUserModalComponent,
    ModalStepComponent,
  ],
  providers: [
    EmailValidator,
    ScoringMatrixCalculation
  ],
  entryComponents: [
    ConfirmationModalComponent,
    UpdatePasswordModalComponent,
    NewUserModalComponent
  ]
})
export class SharedModule { }
