import { NgModel } from '@angular/forms';
import { debounceTime, distinctUntilChanged, skip } from 'rxjs/operators';
import { Directive, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

@Directive({
  selector: '[appNgModelChangeDebounced]',
})
export class NgModelChangeDebouncedDirective implements OnDestroy {
  @Output()
  appNgModelChangeDebounced = new EventEmitter<any>();
  @Input()
  appNgModelChangeDebounceTime = 750;

  subscription: Subscription;
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  constructor(private ngModel: NgModel) {
    this.subscription = this.ngModel.control.valueChanges.pipe(
      skip(1),
      distinctUntilChanged(),
      debounceTime(this.appNgModelChangeDebounceTime)
    ).subscribe((value) => this.appNgModelChangeDebounced.emit(value));
  }
}
