import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import {NgxSmartModalService} from "ngx-smart-modal";
import {EventEmitter, Input, Output} from "@angular/core";

export abstract class ModalBase {
  faTimes = faTimes;

  @Input()
  identifier = 'modal';

  @Input()
  title: string;

  @Input()
  subtitle: string;

  @Input()
  confirmDisabled: boolean;

  @Input()
  confirmText = 'Confirm';

  @Input()
  hideConfirm = false;

  @Output()
  onConfirm: EventEmitter<any> = new EventEmitter<any>();

  protected constructor(public ngxSmartModalService: NgxSmartModalService) {
  }

  close(): void {
    const modal = this.ngxSmartModalService.getModal(this.identifier);
    this.ngxSmartModalService.resetModalData(this.identifier);
    modal.close();
  }

  confirm(): void {
    this.onConfirm.emit();
    this.close();
  }

}
