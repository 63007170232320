import {Component, Input, OnInit} from '@angular/core';
import {NavLinkGroupComponent} from '../nav-link-group/nav-link-group.component';

@Component({
  selector: 'app-nav-link',
  templateUrl: './nav-link.component.html',
  styleUrls: ['./nav-link.component.scss']
})
export class NavLinkComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  route: string;

  @Input()
  active: boolean;

  @Input()
  externalLink = false;

  constructor(public navLinkGroup: NavLinkGroupComponent) {
    navLinkGroup.addItem(this);
  }

  ngOnInit() {
  }

}
