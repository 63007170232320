import { Component, EventEmitter, OnInit, Output, ViewEncapsulation, Input } from '@angular/core';
import { ToggleButtonComponent } from './toggle-button/toggle-button.component';

@Component({
  selector: 'app-toggle-button-group',
  templateUrl: './toggle-button-group.component.html',
  styleUrls: ['./toggle-button-group.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ToggleButtonGroupComponent implements OnInit {

  items: ToggleButtonComponent[] = [];

  @Input()
  canDeselect = false;

  @Output()
  selectedItemChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {}

  addItem(item: ToggleButtonComponent): void {
    this.items.push(item);
  }

  itemSelected(item: ToggleButtonComponent): void {
    const selected = item.selected;
    this.items.forEach(x => x.selected = false);
    item.selected = selected;

    if (!item.selected) {
      item = Object.assign({}, item);
      item.value = null;
    }

    this.selectedItemChanged.emit(item);
  }
}
