import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConversionService {

  degreesCelsiusToDegreesFahrenheit(degreesCelsius: any): number {
    if (degreesCelsius > 0)
      return Math.round((degreesCelsius * 1.8) + 32);
    return 0;
  }

  degreesFahrenheitToDegreesCelsius(degreesFahrenheit: number): number {
    if (degreesFahrenheit > 0)
      return Math.round((degreesFahrenheit - 32) * 0.556);
    return 0;
  }

  kilogramsToStonesAndPounds(kilograms: any): any {
    if (kilograms > 0) {
      const lbs = kilograms * 2.205;
      return {
        st: Math.floor(lbs / 14),
        lbs: Math.round(lbs % 14)
      };
    }
    return {
      st: 0,
      lbs: 0
    };
  }

  stonesAndPoundsToKilograms(stones: any, pounds: any): number {
    const stInLbs = stones * 14;
    const lbs = stInLbs + parseInt(pounds);
    if (lbs > 0)
      return Math.round(lbs / 2.205);
    return 0;
  }

  feetAndInchesToCentimetres(feet: any, inches: any): number {
    const ftInInches = feet * 12;
    const totalInches = ftInInches + parseInt(inches);
    if (totalInches > 0)
      return Math.round(totalInches * 2.54);
    return 0;
  }

  inchesToCentimetres(inches: any): number {
    if (inches > 0)
      return Math.round(inches * 2.54);
    return 0;
  }

  centimetresTofeetAndInches(centimetres: any): any {
    if (centimetres > 0) {
      const inches = centimetres / 2.54;
      return {
        ft: Math.floor(inches / 12),
        in: Math.round(inches % 12)
      };
    }
    return {
      ft: 0,
      in: 0
    };
  }

  centimetresToInches(centimetres: any): any {
    if (centimetres > 0) {
      return Math.floor(centimetres / 2.54);
    }
    return 0;
  }

}
