import {Deserializable} from "@shared/types/interfaces/deserializable.interface";

export class Account implements Deserializable {
  id: string;
  name: string;
  description: string;
  roles: any[];

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class CreateAccount {
  name: string;
  description: string;
}
