import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { AuthService } from "@core/services/auth/auth.service";
import { Router } from '@angular/router';
import { UpdatePasswordModalComponent } from "@shared/components/modals/update-password-modal/update-password-modal.component";
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {
  faTimes = faTimes;

  @Output() onToggleMenu: EventEmitter<any> = new EventEmitter();

  openGroupTitle = 'Menu';

  constructor(private authService: AuthService,
    private router: Router,
    public ngxSmartModalService: NgxSmartModalService) { }

  ngOnInit() { }

  toggleMenu(): void {
    this.onToggleMenu.emit();
  }

  groupOpened(groupTitle: string): void {
    this.openGroupTitle = groupTitle;
  }

  isGroupOpen(groupTitle: string): boolean {
    return this.openGroupTitle === groupTitle;
  }

  logout(): void {
    this.toggleMenu();
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  updatePassword(): void {
    let modal;
    let user = this.authService.getUser();
    modal = this.ngxSmartModalService.create('changePassword', UpdatePasswordModalComponent, { dismissable: false });
    modal.setData({ user: user });
    modal.open();
  }
}
