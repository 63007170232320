import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-regular-svg-icons';
import {SelectListItemComponent} from '@shared/components';
import {CheckListItemComponent} from "@shared/components";

@Component({
  selector: 'app-select-list',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.scss']
})
export class SelectListComponent implements OnInit {

  items: SelectListItemComponent[] = [];

  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;

  @Input()
  multiSelect: boolean = false;

  @Input()
  placeholder: string = 'Placeholder';

  @Input()
  disabled = false;

  @Output()
  itemsToggled: EventEmitter<any> = new EventEmitter<any>();

  dropdownOpen = false;

  constructor() { }

  ngOnInit() {}

  addItem(item: SelectListItemComponent): void {
    this.items.push(item);
  }

  toggleDropdown(): void {
    if (!this.disabled) {
      this.dropdownOpen = !this.dropdownOpen;
    }
  }

  selectChange(items: CheckListItemComponent[]): void {
    this.items.forEach(x => {
      x.checked = items.some(y => y.value === x.value);
    });

    let result = this.items.filter(x => x.checked);

    if (result.length == 0)
      this.itemsToggled.emit([{ value: null }]);
    else
      this.itemsToggled.emit(result);

    if (!this.multiSelect) {
      this.dropdownOpen = false;
    }
  }

  getSelectedItems(): SelectListItemComponent[] {
    return this.items.filter(x => x.checked);
  }

  getTitle(): string {
    const selectedItems = this.getSelectedItems();
    if (selectedItems.length === 0) {
      return '';
    }
    if (selectedItems.length === 1) {
      return selectedItems[0].title;
    }

    return selectedItems.length + ' items selected';
  }

}
