export class Document {
  id: string;
  name: string;
  description: string;
  uri: string;
}

export class DocumentUpload {
  fileContent: string | ArrayBuffer;
  fileType: string;
  fileName: string;

  constructor(fileName: string, fileType: string, fileContent: string | ArrayBuffer) {
    this.fileName = fileName;
    this.fileType = fileType;
    this.fileContent = fileContent;
  }
}
