import {Component, OnInit} from '@angular/core';
import {Filter} from "../filter";
import {FilterGroupComponent} from "../../filter-group.component";
import * as moment from 'moment';

@Component({
  selector: 'app-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss']
})
export class DateRangeFilterComponent extends Filter implements OnInit {

  fromDate: moment.Moment;
  toDate: moment.Moment;

  constructor(public filterGroup: FilterGroupComponent) {
    super(filterGroup);
  }

  ngOnInit() {
  }

  updateFromDate(date: any): void {
    if (date !== null) {
      this.fromDate = moment(date, "DD/MM/YYYY").startOf('day');
      this.filterCondition.type = 'date';
    }

    if (this.fromDate != null && this.toDate != null) {
      this.setAsValid();
      this.filterGroup.applyFilters();
    } else {
      this.isValid = false;
    }
  }

  updateToDate(date: any): void {
    if (date !== null) {
      this.toDate = moment(date, "DD/MM/YYYY").endOf('day');
      this.filterCondition.type = 'date';
    }

    if (this.fromDate != null && this.toDate != null) {
      this.setAsValid();
      this.filterGroup.applyFilters();
    } else {
      this.isValid = false;
    }
  }

  updateDateRange(dateRange: any): void {
    if (dateRange !== null) {
      this.filterCondition.type = 'date';
      switch (dateRange) {
      case "today":
        this.fromDate = moment().startOf('day');
        this.toDate = moment().endOf('day');
        break;
      case "yesterday":
        this.fromDate = moment().subtract(1, 'day').startOf('day');
        this.toDate = moment().subtract(1, 'day').endOf('day');
        break;
      case "this_week":
        this.fromDate = moment().startOf('week');
        this.toDate = moment().endOf('week');
        break;
      case "this_month":
        this.fromDate = moment().startOf('month');
        this.toDate = moment().endOf('month');
        break;
      default:
      }

      if (this.fromDate != null && this.toDate != null) {
        this.setAsValid();
        this.filterGroup.applyFilters();
      } else {
        this.isValid = false;
      }
    }
  }

  getSummary(): string {
    if (this.fromDate != null && this.toDate != null) {
      return `<div>Range <strong class="highlight">${this.fromDate.format('DD/MM/YYYY')} to ${this.toDate.format('DD/MM/YYYY')}</strong></div>`;
    }
    return '';
  }

  getQuery(): any {
    return { from: this.fromDate.format('YYYY-MM-DDTHH:mm:ss'), to: this.toDate.format('YYYY-MM-DDTHH:mm:ss') }
  }
}
