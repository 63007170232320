import { Component, OnInit } from '@angular/core';
import { Filter } from "../filter";
import { FilterGroupComponent } from "../../filter-group.component";
import { AccountService } from "@core/services";

@Component({
  selector: 'app-user-role-filter',
  templateUrl: './user-role-filter.component.html',
  styleUrls: ['./user-role-filter.component.scss']
})
export class UserRoleFilterComponent extends Filter implements OnInit {

  roles: any[];

  constructor(public filterGroup: FilterGroupComponent,
              public accountService: AccountService) {
    super(filterGroup);
  }

  ngOnInit() {
    this.accountService.getCurrentUserAccount$()
      .subscribe(account => this.roles = account.roles);
  }

  updateOperator(operator: string): void {
    this.filterCondition.operator = operator;
    if (this.checkFilterValidity()) {
      this.setAsValid();
    } else {
      this.filterCondition.isValid = false;
    }
  }

  updateValue(value: string): void {
    this.filterCondition.value = value;
    this.updateOperator('is');
    this.setAsValid();
    this.filterGroup.applyFilters();
  }

  private checkFilterValidity(): boolean {
    return this.filterCondition.operator !== null &&
      this.filterCondition.operator !== '' &&
      this.filterCondition.value !== null &&
      this.filterCondition.value !== '';
  }

  getQuery(): any {
    return { roleId: this.filterCondition.value.id };
  }

  getSummary(): string {
    const operator = this.filterCondition.operator === 'is' ? 'is' : 'is not';
    const value = this.filterCondition.value.title;
    return `<div>Role ${operator} <strong>${value}</strong></div>`;
  }

}
