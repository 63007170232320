import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss']
})
export class SelectInputComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  subTitle: string;

  @Input()
  items: any[];

  @Input()
  multiSelect: boolean = false;

  @Input()
  field: any;

  @Output()
  valueChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() { }

  updateValue(input: any): void {

    if (this.multiSelect) {
      this.field = input.map(e => {
        return this.items.find(sc => {
          return sc === e.value;
        });
      });
    } else {
      this.field = input[0].value;
    }

    this.valueChanged.emit(this.field);
  }

  isItemSelected(value: number): boolean {
    if (this.field == null || (Array.isArray(this.field) && this.field[0] == null))
      return false;

    if (this.multiSelect) {
      return this.field.findIndex(cat => cat.value === value) != -1;
    } else {
      return this.field.value === value;
    }
  }
}
