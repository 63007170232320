import { Component, OnInit } from '@angular/core';
import { FilterSection } from "../filter-section";
import { FilterBarComponent } from "../../../filter-bar.component";
import { FilterCondition } from "@shared/types/interfaces/filterCondition.interface";

@Component({
  selector: 'app-applicant-filter-section',
  templateUrl: './applicant-filter-section.component.html',
  styleUrls: ['./applicant-filter-section.component.scss']
})
export class ApplicantFilterSectionComponent extends FilterSection implements OnInit {

  constructor(public filterBar: FilterBarComponent) {
    super(filterBar);
  }

  ngOnInit() {
    this.filterConditions = [{type: ''}] as FilterCondition[];
  }
}
