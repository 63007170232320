import {Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges} from '@angular/core';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faChevronLeft, faSearch } from '@fortawesome/pro-regular-svg-icons';
import {Router} from '@angular/router';

@Component({
  selector: 'app-quick-menu',
  templateUrl: './quick-menu.component.html',
  styleUrls: ['./quick-menu.component.scss']
})
export class QuickMenuComponent implements OnInit, OnChanges {
  @Input()
  menu: any;

  @Input()
  active = false;

  @Output()
  itemClicked: EventEmitter<any> = new EventEmitter<any>();

  selectedMenu: any;
  menuItems: any;
  initialMenuItems: any;
  showSubmenu = false;
  faChevronLeft = faChevronLeft;
  faSearch = faSearch;
  faTimes = faTimes;
  searchActive = false;

  constructor(private router: Router) { }

  ngOnInit() {
    this.selectedMenu = this.menu;
    this.menuItems = this.menu.items;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.active && changes.active.previousValue === true && changes.active.currentValue === false) {
      this.selectedMenu = this.menu;
      this.menuItems = this.menu.items;
      this.showSubmenu = false;
    }
  }

  menuItemClick(menuItem, e: Event): void {
    if (!menuItem.disabled) {
      if (this.hasSubmenu(menuItem)) {
        this.selectedMenu = menuItem.submenu;
        this.menuItems = menuItem.submenu.items;
        this.showSubmenu = true;
        e.stopPropagation();
      } else if (menuItem.route) {
        if (menuItem.newTab) {
          window.open(menuItem.route, '_blank');
        } else {
          this.router.navigate([menuItem.route]);
        }
      } else {
        this.itemClicked.emit(menuItem);
      }
    }
  }

  backToMainMenu($event): void {
    $event.stopPropagation();
    this.selectedMenu = this.menu;
    this.menuItems = this.menu.items;
    this.showSubmenu = false;
  }

  hasSubmenu(menuItem) {
    return menuItem.submenu !== undefined;
  }

  openSearch(event): void {
    event.stopPropagation();
    this.searchActive = true;
    this.initialMenuItems = this.menuItems;
  }

  closeSearch(event): void {
    event.stopPropagation();
    this.menuItems = this.initialMenuItems;
    this.searchActive = false;
  }

  searchMenuItems(searchTerm: string): void {
    this.menuItems = this.initialMenuItems.filter(mi => {
      return mi.title.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }
}
