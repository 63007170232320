import { AccordianComponent } from "@shared/components/controls/accordian/accordian.component";
import { AccordianItemComponent } from "@shared/components/controls/accordian/accordian-item/accordian-item.component";
import { ActionListComponent } from "@shared/components/controls/action-list/action-list.component";
import { ActionListItemComponent } from "@shared/components/controls/action-list/action-list-item/action-list-item.component";
import { AddressLookupComponent } from "@shared/components/controls/address-lookup/address-lookup.component";
import { BadgeComponent } from "@shared/components/controls/badge/badge.component";
import { AddButtonComponent } from "@shared/components/controls/buttons/add-button/add-button.component";
import { RemoveButtonComponent } from "@shared/components/controls/buttons/remove-button/remove-button.component";
import { CheckButtonComponent } from "@shared/components/controls/buttons/check-button/check-button.component";
import { CloseDrawerButtonComponent } from "@shared/components/controls/buttons/close-drawer-button/close-drawer-button.component";
import { OpenDrawerButtonComponent } from "@shared/components/controls/buttons/open-drawer-button/open-drawer-button.component";
import { CalendarComponent } from "@shared/components/controls/calendar/calendar.component";
import { CheckListComponent } from "@shared/components/controls/check-list/check-list.component";
import { CheckListItemComponent } from "@shared/components/controls/check-list/check-list-item/check-list-item.component";
import { DatePickerComponent } from "@shared/components/controls/date-picker/date-picker.component";
import { FileUploadComponent } from "@shared/components/controls/file-upload/file-upload.component";
import { HeightInputComponent } from "@shared/components/controls/inputs/height-input/height-input.component";
import { TemperatureInputComponent } from "@shared/components/controls/inputs/temperature-input/temperature-input.component";
import { WeightInputComponent } from "@shared/components/controls/inputs/weight-input/weight-input.component";
import { SelectInputComponent } from "@shared/components/controls/inputs/select-input/select-input.component";
import { ItemListComponent } from "@shared/components/controls/item-list/item-list.component";
import { ItemListItemComponent } from "@shared/components/controls/item-list/item-list-item/item-list-item.component";
import { ProgressIndicatorComponent } from "@shared/components/controls/progress-indicator/progress-indicator.component";
import { ScrollingTabsComponent } from "@shared/components/controls/scrolling-tabs/scrolling-tabs.component";
import { ScrollingTabComponent } from "@shared/components/controls/scrolling-tabs/scrolling-tab/scrolling-tab.component";
import { SearchBoxComponent } from "@shared/components/controls/search-box/search-box.component";
import { SelectListComponent } from "@shared/components/controls/select-list/select-list.component";
import { SelectListItemComponent } from "@shared/components/controls/select-list/select-list-item/select-list-item.component";
import { SliderComponent } from "@shared/components/controls/slider/slider.component";
import { SlideComponent } from "@shared/components/controls/slider/slide/slide.component";
import { StringListComponent } from "@shared/components/controls/string-list/string-list.component";
import { StringListItemComponent } from "@shared/components/controls/string-list/string-list-item/string-list-item.component";
import { TabsComponent } from "@shared/components/controls/tabs/tabs.component";
import { TabComponent } from "@shared/components/controls/tabs/tab/tab.component";
import { TimePickerComponent } from "@shared/components/controls/time-picker/time-picker.component";
import { ToggleButtonGroupComponent } from "@shared/components/controls/toggle-button-group/toggle-button-group.component";
import { ToggleButtonComponent } from "@shared/components/controls/toggle-button-group/toggle-button/toggle-button.component";
import { ToggleButtonIconComponent } from "@shared/components/controls/toggle-button-icon-group/toggle-button-icon/toggle-button-icon.component";
import { ToggleButtonIconGroupComponent } from "@shared/components/controls/toggle-button-icon-group/toggle-button-icon-group.component";
import { ToggleGroupComponent } from "@shared/components/controls/toggle-group/toggle-group.component";
import { ToggleComponent } from "@shared/components/controls/toggle-group/toggle/toggle.component";
import { ResidentDetailsComponent } from "@shared/components/resident/resident-details/resident-details.component";
import { DividerComponent } from "@shared/components/controls/divider/divider.component";
import { ConfirmationModalComponent } from "@shared/components/modals/confirmation-modal/confirmation-modal.component";
import { MultiStageModalComponent } from "@shared/components/modals/multi-stage-modal/multi-stage-modal.component";
import { SectionContainerComponent } from "@shared/components/controls/section-container/section-container.component";
import { SectionComponent } from "@shared/components/controls/section-container/section/section.component";
import { ProgressItemComponent } from "@shared/components/controls/section-container/progress-item/progress-item.component";
import { SubSectionComponent } from "@shared/components/controls/section-container/section/sub-section/sub-section.component";
import { StepComponent } from "@shared/components/controls/section-container/section/sub-section/step/step.component";
import { MeasureComponent } from "@shared/components/controls/measure/measure.component";
import { ExpandContentComponent } from "@shared/components/controls/expand-content/expand-content.component";
import { FilterBarComponent } from "@shared/components/to-organise/filter-bar/filter-bar.component";
import { FilterGroupComponent } from "@shared/components/to-organise/filter-bar/filter-group/filter-group.component";
import { DateRangeFilterComponent } from "@shared/components/to-organise/filter-bar/filter-group/filters/date-range-filter/date-range-filter.component";
import { MaritalStatusFilterComponent } from "@shared/components/to-organise/filter-bar/filter-group/filters/marital-status-filter/marital-status-filter.component";
import { DecorationOrMedalFilterComponent } from "@shared/components/to-organise/filter-bar/filter-group/filters/decoration-or-medal-filter/decoration-or-medal-filter.component";
import { LastUpdatedFilterComponent } from "@shared/components/to-organise/filter-bar/filter-group/filters/last-updated-filter/last-updated-filter.component";
import { ApplicationStatusFilterComponent } from "@shared/components/to-organise/filter-bar/filter-group/filters/application-status-filter/application-status-filter.component";
import { UserStatusFilterComponent } from "@shared/components/to-organise/filter-bar/filter-group/filters/user-status-filter/user-status-filter.component";
import { UserRoleFilterComponent } from "@shared/components/to-organise/filter-bar/filter-group/filters/user-role-filter/user-role-filter.component";
import { FilterSectionComponent } from "@shared/components/to-organise/filter-bar/filter-section/filter-section.component";
import { ApplicantFilterSectionComponent } from "@shared/components/to-organise/filter-bar/filter-section/sections/applicant-filter-section/applicant-filter-section.component";
import { ApplicationFilterSectionComponent } from "@shared/components/to-organise/filter-bar/filter-section/sections/application-filter-section/application-filter-section.component";
import { ActivityFilterSectionComponent } from "@shared/components/to-organise/filter-bar/filter-section/sections/activity-filter-section/activity-filter-section.component";
import { UserFilterSectionComponent } from "@shared/components/to-organise/filter-bar/filter-section/sections/user-filter-section/user-filter-section.component";
import { SearchBarComponent } from "@shared/components/to-organise/search-bar/search-bar.component";
import { YesNoInputComponent } from "@shared/components/controls/inputs/yes-no-input/yes-no-input.component";
import { TextareaInputComponent } from "@shared/components/controls/inputs/textarea-input/textarea-input.component";
import { TextInputComponent } from "@shared/components/controls/inputs/text-input/text-input.component";
import { CircumferenceInputComponent } from "@shared/components/controls/inputs/circumference-input/circumference-input.component";
import { BankComponent } from "@shared/components/controls/bank/bank.component";
import { BankCardComponent } from "@shared/components/controls/bank/bank-card/bank-card.component";
import { PillComponent } from "@shared/components/controls/pill/pill.component";
import { ProfileImagePreviewComponent } from "@shared/components/controls/profile-image-preview/profile-image-preview.component";
import { PagerComponent } from "@shared/components/controls/pager/pager.component";
import { ReviewScheduleComponent } from "@shared/components/to-organise/review-schedule/review-schedule.component";
import { SidepanelComponent } from "@shared/components/to-organise/sidepanel/sidepanel.component";
import { SidepanelAreaComponent } from "@shared/components/to-organise/sidepanel/sidepanel-area/sidepanel-area.component";
import { QuickMenuComponent } from "@shared/components/to-organise/quick-menu/quick-menu.component";
import { QuickMenuItemComponent } from "@shared/components/to-organise/quick-menu/quick-menu-item/quick-menu-item.component";
import { UpdatePasswordModalComponent } from "@shared/components/modals/update-password-modal/update-password-modal.component";
import { NumberSelectComponent } from "@shared/components/controls/number-select/number-select.component";

export const components: any[] = [
  AccordianComponent,
  AccordianItemComponent,
  ActionListComponent,
  ActionListItemComponent,
  AddressLookupComponent,
  BadgeComponent,
  AddButtonComponent,
  RemoveButtonComponent,
  CheckButtonComponent,
  CloseDrawerButtonComponent,
  OpenDrawerButtonComponent,
  CalendarComponent,
  CheckListComponent,
  CheckListItemComponent,
  DatePickerComponent,
  DividerComponent,
  FileUploadComponent,
  HeightInputComponent,
  TemperatureInputComponent,
  WeightInputComponent,
  ItemListComponent,
  ItemListItemComponent,
  ProgressIndicatorComponent,
  SelectInputComponent,
  ScrollingTabsComponent,
  ScrollingTabComponent,
  SearchBoxComponent,
  SelectListComponent,
  SelectListItemComponent,
  SliderComponent,
  SlideComponent,
  StringListComponent,
  StringListItemComponent,
  TabsComponent,
  TabComponent,
  TimePickerComponent,
  ToggleButtonGroupComponent,
  ToggleButtonComponent,
  ToggleButtonIconComponent,
  ToggleButtonIconGroupComponent,
  ToggleGroupComponent,
  ToggleComponent,
  ResidentDetailsComponent,
  ConfirmationModalComponent,
  MultiStageModalComponent,
  SectionContainerComponent,
  SectionComponent,
  ProgressItemComponent,
  SubSectionComponent,
  StepComponent,
  MeasureComponent,
  ExpandContentComponent,
  YesNoInputComponent,
  TextareaInputComponent,
  TextInputComponent,
  CircumferenceInputComponent,
  BankComponent,
  BankCardComponent,
  PillComponent,
  ProfileImagePreviewComponent,
  PagerComponent,
  ReviewScheduleComponent,
  FilterBarComponent,
  FilterGroupComponent,
  DateRangeFilterComponent,
  MaritalStatusFilterComponent,
  DecorationOrMedalFilterComponent,
  LastUpdatedFilterComponent,
  ApplicationStatusFilterComponent,
  UserStatusFilterComponent,
  UserRoleFilterComponent,
  FilterSectionComponent,
  ApplicantFilterSectionComponent,
  ApplicationFilterSectionComponent,
  ActivityFilterSectionComponent,
  UserFilterSectionComponent,
  SearchBarComponent,
  SidepanelComponent,
  SidepanelAreaComponent,
  QuickMenuComponent,
  QuickMenuItemComponent,
  UpdatePasswordModalComponent,
  NumberSelectComponent
];

export * from './controls/accordian/accordian.component';
export * from './controls/accordian/accordian-item/accordian-item.component';
export * from './controls/action-list/action-list.component';
export * from './controls/action-list/action-list-item/action-list-item.component';
export * from './controls/address-lookup/address-lookup.component';
export * from './controls/badge/badge.component';
export * from './controls/buttons/add-button/add-button.component';
export * from './controls/buttons/remove-button/remove-button.component';
export * from './controls/buttons/check-button/check-button.component';
export * from './controls/buttons/open-drawer-button/open-drawer-button.component';
export * from './controls/buttons/close-drawer-button/close-drawer-button.component';
export * from './controls/calendar/calendar.component';
export * from './controls/check-list/check-list.component';
export * from './controls/check-list/check-list-item/check-list-item.component';
export * from './controls/date-picker/date-picker.component';
export * from './controls/divider/divider.component';
export * from './controls/file-upload/file-upload.component';
export * from './controls/inputs/height-input/height-input.component';
export * from './controls/inputs/temperature-input/temperature-input.component';
export * from './controls/inputs/weight-input/weight-input.component';
export * from './controls/inputs/select-input/select-input.component';
export * from './controls/item-list/item-list.component';
export * from './controls/item-list/item-list-item/item-list-item.component';
export * from './controls/progress-indicator/progress-indicator.component';
export * from './controls/scrolling-tabs/scrolling-tabs.component';
export * from './controls/scrolling-tabs/scrolling-tab/scrolling-tab.component';
export * from './controls/search-box/search-box.component';
export * from './controls/select-list/select-list.component';
export * from './controls/select-list/select-list-item/select-list-item.component';
export * from './controls/slider/slider.component';
export * from './controls/slider/slide/slide.component';
export * from './controls/string-list/string-list.component';
export * from './controls/string-list/string-list-item/string-list-item.component';
export * from './controls/tabs/tabs.component';
export * from './controls/tabs/tab/tab.component';
export * from './controls/time-picker/time-picker.component';
export * from './controls/toggle-button-group/toggle-button-group.component';
export * from './controls/toggle-button-group/toggle-button/toggle-button.component';
export * from './controls/toggle-button-icon-group/toggle-button-icon-group.component';
export * from './controls/toggle-button-icon-group/toggle-button-icon/toggle-button-icon.component';
export * from './controls/toggle-group/toggle-group.component';
export * from './controls/toggle-group/toggle/toggle.component';
export * from './controls/inputs/circumference-input/circumference-input.component';
export * from './controls/inputs/textarea-input/textarea-input.component';
export * from './controls/inputs/yes-no-input/yes-no-input.component';
export * from './resident/resident-details/resident-details.component';
export * from './modals/confirmation-modal/confirmation-modal.component';
export * from './controls/section-container/section-container.component';
export * from './controls/section-container/progress-item/progress-item.component';
export * from './controls/section-container/section/section.component';
export * from './controls/section-container/section/sub-section/sub-section.component';
export * from './controls/section-container/section/sub-section/step/step.component';
export * from './controls/expand-content/expand-content.component';
export * from './controls/measure/measure.component';
export * from './controls/bank/bank.component';
export * from './controls/bank/bank-card/bank-card.component';
export * from './controls/pill/pill.component';
export * from './controls/profile-image-preview/profile-image-preview.component';
export * from './controls/pager/pager.component';
export * from './to-organise/review-schedule/review-schedule.component';
export * from './modals/update-password-modal/update-password-modal.component';
export * from './controls/number-select/number-select.component';

export * from './to-organise/filter-bar/filter-bar.component';
export * from './to-organise/filter-bar/filter-group/filter-group.component';
export * from './to-organise/filter-bar/filter-group/filters/date-range-filter/date-range-filter.component';
export * from './to-organise/filter-bar/filter-group/filters/marital-status-filter/marital-status-filter.component';
export * from './to-organise/filter-bar/filter-group/filters/decoration-or-medal-filter/decoration-or-medal-filter.component';
export * from './to-organise/filter-bar/filter-group/filters/last-updated-filter/last-updated-filter.component';
export * from './to-organise/filter-bar/filter-group/filters/application-status-filter/application-status-filter.component';
export * from './to-organise/filter-bar/filter-group/filters/user-status-filter/user-status-filter.component';
export * from './to-organise/filter-bar/filter-group/filters/user-role-filter/user-role-filter.component';
export * from './to-organise/filter-bar/filter-section/filter-section.component';
export * from './to-organise/filter-bar/filter-section/sections/applicant-filter-section/applicant-filter-section.component';
export * from './to-organise/filter-bar/filter-section/sections/application-filter-section/application-filter-section.component';
export * from './to-organise/filter-bar/filter-section/sections/activity-filter-section/activity-filter-section.component';
export * from './to-organise/filter-bar/filter-section/sections/user-filter-section/user-filter-section.component';
export * from './to-organise/search-bar/search-bar.component';
export * from './to-organise/sidepanel/sidepanel.component';
export * from './to-organise/sidepanel/sidepanel-area/sidepanel-area.component';
export * from './to-organise/quick-menu/quick-menu.component';
export * from './to-organise/quick-menu/quick-menu-item/quick-menu-item.component';
