import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { ResidentApplicationFilter } from '@shared/filters';
import { Observable, BehaviorSubject } from 'rxjs';
import { ResidentApplication } from '@shared/types/models';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ResidentApplicationService extends ApiService<ResidentApplication> {

  private residentApplicationUpdatedSource = new BehaviorSubject<ResidentApplication>(null);
  residentApplicationUpdated$ = this.residentApplicationUpdatedSource.asObservable();

  changeResidentApplication(residentApplication: ResidentApplication) {
    this.residentApplicationUpdatedSource.next(residentApplication);
  }

  public residentApplication$: Observable<ResidentApplication>;

  constructor(protected httpClient: HttpClient) {
    super(httpClient, 'residentapplications');
  }

  public getFilter(): ResidentApplicationFilter {
    return new ResidentApplicationFilter();
  }

  public updateProgress$(residentApplication: ResidentApplication): Observable<ResidentApplication> {
    const progressUrl = this.url + '/progress';
    return this.httpClient.put<ResidentApplication>(progressUrl, residentApplication, this.httpOptions);
  }
  
  public admit$(id: string): Observable<ResidentApplication> {
    const admitUrl = this.url + '/' + id + '/admit';
    return this.httpClient.post<ResidentApplication>(admitUrl, {}, this.httpOptions);
  }

  public decline$(model: ResidentApplication): Observable<ResidentApplication> {
    const declineUrl = this.url + '/decline';
    return this.httpClient.post<ResidentApplication>(declineUrl, model, this.httpOptions);
  }
}
