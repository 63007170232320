import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  nullOrEmpty(input: any) {
    return input == null || input.length <= 0;
  }

  nullOrEmptyGuid(input: any) {
    return input == null || input === "00000000-0000-0000-0000-000000000000";
  }

  dateIsValid(date: Date): boolean {
    if (date == null)
      return false;
    let dateMoment = moment(date);
    return dateMoment.year() > 1;
  }
 
}
