import {AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import {fromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, tap} from 'rxjs/operators';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements AfterViewInit {
  faSearch = faSearch;

  // @ts-ignore
  @ViewChild('input')
  input: ElementRef;

  @Output()
  searchValueChanged: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngAfterViewInit() {
    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(150),
        distinctUntilChanged(),
        tap((text) => {
          this.searchValueChanged.emit(this.input.nativeElement.value);
        })
      )
      .subscribe();
  }

}
