import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Account } from '@shared/types/models';
import { HttpClient } from '@angular/common/http';
import { AccountFilter } from '@shared/filters';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountService extends ApiService<Account> {
  public account$: Observable<Account>;

  public filter = new AccountFilter();

  private accountUpdatedSource = new BehaviorSubject<Account>(null);
  accountUpdated$ = this.accountUpdatedSource.asObservable();

  accountUpdatedEvent(account) {
    this.accountUpdatedSource.next(account);
  }

  constructor(protected httpClient: HttpClient) {
    super(httpClient, 'accounts');
  }

  public getFilter(): AccountFilter {
    return this.filter;
  }

  public getCurrentUserAccount$(): Observable<Account> {
    const currentUserUrl = this.url + '/currentuser';
    return this.httpClient.get<Account>(currentUserUrl);
  }
  
  public addRole$(id: string, role: any): Observable<Account> {
    const url = `${this.url}/${id}/roles`;
    return this.httpClient.post<any>(url, role, this.httpOptions);
  }

  public updateRole$(id: string, role: any): Observable<Account> {
    const url = `${this.url}/${id}/roles`;
    return this.httpClient.put<any>(url, role, this.httpOptions);
  }

  public updateRolePermissions$(id: string, role: any): Observable<Account> {
    const url = `${this.url}/${id}/roles/permissions`;
    return this.httpClient.put<any>(url, role, this.httpOptions);
  }
}
