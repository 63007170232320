import { Injectable } from '@angular/core';
import {Title} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  private _title: string;
  private _crumb: string;
  private _gradient: string;

  constructor(private titleService: Title) { }

  get title(): string {
    return this._title;
  }

  set title(title: string) {
    this.titleService.setTitle('KareInn - ' + title);
    this._title = title;
  }

  get crumb(): string {
    return this._crumb;
  }

  set crumb(crumb: string) {
    this._crumb = crumb;
  }

  get gradient(): string {
    return this._gradient;
  }

  set gradient(gradient: string) {
    this._gradient = gradient;
  }

  getFinalCrumb(): string {
    let crumbs = [];

    if (this._crumb == undefined || this._crumb == '') {
      return this._crumb;
    }

    crumbs = this._crumb.split('/');
    return crumbs[crumbs.length - 1].trim();
  }
}
