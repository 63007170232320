import { Component, OnInit } from '@angular/core';
import {SliderComponent} from '../slider.component';

@Component({
  selector: 'app-slide',
  templateUrl: './slide.component.html',
  styleUrls: ['./slide.component.scss']
})
export class SlideComponent implements OnInit {
  active: boolean;

  constructor(slider: SliderComponent) {
    slider.addSlide(this);
  }

  ngOnInit() {
  }

}
