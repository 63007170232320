import * as rg4js from 'raygun4js';
import { ErrorHandler } from '@angular/core';
import {environment} from '../environments/environment';

const VERSION_NUMBER = '1.0.0.0';
rg4js('apiKey', environment.raygunApiKey);
rg4js('setVersion', VERSION_NUMBER);
if (environment.production) {
  rg4js('enableCrashReporting', true);
}

export class RaygunErrorHandler implements ErrorHandler {
  handleError(e: any): void {
    rg4js('send', {
      error: e
    });
  }
}
