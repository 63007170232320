import { AddressService } from './address/address.service';
import { AuthService } from "./auth/auth.service";
import { ConversionService } from "./conversion/conversion.service";
import { ValidationService } from "./validation/validation.service";
import { EnumerationService } from "./enumeration/enumeration.service";
import { HeaderService } from "./header/header.service";
import { DocumentService } from "./document/document.service";
import { ResidentApplicationService } from "./resident-application/resident-application.service";
import { ScrollTrackingService } from "./scroll-tracking/scroll-tracking.service";
import { UserProfileService } from "./user-profile/user-profile.service";
import { CalendarEventService } from "./calendar-event/calendar-event.service";
import { UserService } from "./user/user.service";
import { AccountService } from "./account/account.service";
import { ConfigurationService } from "./configuration/configuration.service";

export const services: any[] = [
  AddressService,
  AuthService,
  ConversionService,
  ValidationService,
  EnumerationService,
  HeaderService,
  DocumentService,
  ResidentApplicationService,
  ScrollTrackingService,
  UserProfileService,
  CalendarEventService,
  UserService,
  AccountService,
  ConfigurationService];

export * from './address/address.service';
export * from './auth/auth.service';
export * from './conversion/conversion.service';
export * from './validation/validation.service';
export * from './enumeration/enumeration.service';
export * from './header/header.service';
export * from './document/document.service';
export * from './resident-application/resident-application.service';
export * from './scroll-tracking/scroll-tracking.service';
export * from './user-profile/user-profile.service';
export * from './calendar-event/calendar-event.service';
export * from './user/user.service';
export * from './account/account.service';
export * from './configuration/configuration.service';
