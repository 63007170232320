import { Deserializable } from "@shared/types/interfaces/deserializable.interface";
import { Role } from "@shared/types/interfaces/role.interface";

export class User implements Deserializable {
  id: string;
  locationId: string;
  accountId: string;
  isAccountAdmin: boolean;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  phone: string;
  role: Role;
  jobTitle: string;
  permissions: any[];
  active: boolean;
  lastLogin: Date;
  createdDate: Date;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class CreateUser {
  accountId: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  roleId: string;
  locationId: string;
  phone: string;

  constructor(user: User) {
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.email = user.email;
    this.roleId = user.role.id;
    this.locationId = user.locationId;
    this.phone = user.phone;
  }
}

export class UpdatePassword {
  email: string;
  password: string;
}
